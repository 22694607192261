import { AlertService } from './../../../services/alert/alert.service';
import { ExceptionsService } from './../../../services/exceptions/exceptions.service';

import { AuthenticationService } from './../../../services/auth/auth-service.service';
import { DataService } from './../../../services/dataStore/data.service';
import { Subscription, throwError } from 'rxjs';
import { PermissionService } from './../../../services/permission.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/services/shared.service';
import { TaggingService } from './../../../services/tagging.service';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
// import { fabric } from 'fabric';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder, Validators, FormGroup, NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { comaprisionLineData } from './testingLineData';
import { FormCanDeactivate } from '../../can-deactivate/form-can-deactivate';
import { SettingsService } from 'src/app/services/settings/settings.service';
import IdleTimer from '../../idleTimer/idleTimer';
import * as fileSaver from 'file-saver';
import { PopupComponent } from '../../popup/popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, map, take } from 'rxjs/operators';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-comparision3-way',
  templateUrl: './comparision3-way.component.html',
  styleUrls: [
    './comparision3-way.component.scss',
    '../../invoice/view-invoice/view-invoice.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Comparision3WayComponent
  extends FormCanDeactivate
  implements OnInit {
  @ViewChild('canvas') canvas;
  zoomX = 1;
  @ViewChild(PdfViewerComponent, { static: false })
  private pdfViewer: PdfViewerComponent;

  @ViewChild('pdfviewer') pdfviewer;
  @ViewChild('form')
  form: NgForm;
  isEditable: boolean;
  editable: boolean;
  rect: any;
  rectCoords: any;
  inputData:any;
  vendorDetails: FormGroup;
  isRect: boolean;
  isTagged: boolean = false;

  mergedArray: any;
  inputDisplayArray = [];
  vendorData = [];
  lineDisplayData: any;
  lineData = [];
  Itype: string;
  updateInvoiceData: any = [];
  imgArray: { id: string; url: string }[];
  headerName: string;
  editPermissionBoolean: boolean;
  changeApproveBoolean: boolean;
  financeApproveBoolean: boolean;
  fin_boolean: boolean;
  submitBtn_boolean: boolean;
  approveBtn_boolean: boolean;
  innerHeight: number;
  InvoiceHeight: number = 560;
  zoomdata: number = 1;
  showInvoice: any;
  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;
  invoiceID: any;
  routeIdCapture: Subscription;
  byteArray: Uint8Array;

  vendorDetalilsEditBoolean: boolean = false;
  displayRuleDialog: boolean;
  displayErrorDialog: boolean;
  SelectRuleOption = { value: '' };
  SelectErrorOption;
  givenErrors = ['Alternative', 'Identical', 'Adjustment', 'OCR Error'];
  givenRules: any;
  rejectionComments: string = '';
  rejectReason: any;

  grnCreateBoolean: boolean = false;
  GRNObject = [];
  GRNObjectDuplicate = [];

  isPdfAvailable: boolean;
  userDetails: any;
  showPdf: boolean = false;
  btnText = 'View PDF';
  lineCompareData;
  totalLineItems = ['abc', 'xyz'];
  selectedPONumber;
  poList = [];
  filteredPO: any[];
  selectedRule = '3 way';
  lineItemsData: any;
  tagArray: any[];

  lineCount = [];
  save_rule_boolean: boolean;
  selectedRuleID: any;
  approvalType: any;
  currentTab = 'vendor';
  lineItems: any;
  inv_itemcode: any;
  po_itemcode: any;
  vendorAcId: any;
  mappedData: any;
  zoomVal: number = 0.8;

  isImgBoolean: boolean;
  financeapproveDisplayBoolean: boolean;
  displayrejectDialog: boolean;
  // lineDescription ="Select Line items";
  timer: any;
  callSession: any;
  invoiceNumber = '';
  vendorName: any;
  isGRNDataLoaded: boolean;
  content_type: any;
  lineTabBoolean: boolean;
  grnLineCount: any;

  rotation = 0;
  addrejectcmtBool: boolean;
  poLineData = [];
  isAdmin: boolean;
  GRN_PO_Bool: boolean;
  GRN_PO_Data = [];
  GRN_PO_tags = [
    { TagName: 'Description', linedata: [] },
    { TagName: 'PO Qty', linedata: [] },
    { TagName: 'AmountExcTax', linedata: [] },
    { TagName: 'GRN - Quantity', linedata: [] },
    { TagName: 'UnitPrice', linedata: [] },
    { TagName: 'PO LineNumber', linedata: [] },
    { TagName: 'Actions', linedata: [] }
  ];
  validatePOInvUnit = [];
  validateUnitpriceBool: boolean;
  grnList = [];
  selectedGRNList = [];
  currentlyOpenedItemIndex = -1;
  GRNTabData: any;
  grnTabDatalength: number;
  batchData: any;
  progressDailogBool: boolean;
  portalName: string;
  GRNDialogBool: boolean;
  headerpop: string = '350px';
  descrptonBool = false;
  polineTableData = [
    { TagName: 'LineNumber', linedata: [] },
    { TagName: 'ItemId', linedata: [] },
    { TagName: 'Name', linedata: [] },
    { TagName: 'ProcurementCategory', linedata: [] },
    { TagName: 'PurchQty', linedata: [] },
    { TagName: 'UnitPrice', linedata: [] },
    { TagName: 'DiscAmount', linedata: [] },
    { TagName: 'DiscPercent', linedata: [] }
  ];
  POlineBool: boolean;
  poDocId: any;
  po_num: any;
  subStatusId: any;
  isAmtStr: boolean;
  isEmpty: boolean;
  documentViewBool: boolean;
  isDesktop: boolean;
  APIResponse: any;
  filteredPOLine = [];
  isBatchFailed: boolean;
  batch_count = 0;
  invoice_subTotal:number;
  enable_create_grn:boolean;
  p_width: string;
  poNumbersList: any;
  vendorId: any;
  PO_GRN_Number_line = [];
  selectedGRNLines = [];
  activePOId: string;
  activeGRNId: string;
  poDate: any;
  displayYear;
  minDate: Date;
  maxDate: Date;
  lastYear: number;
  months: string[];
  selectedMonth: string;
  selectDate: Date;
  filterDataPO: any;
  searchPOArr: any;
  status_arr: any[];
  filterGRNlist: any[];
  refreshPOBool: boolean;
  GRN_line_total:number = 0;
  progress: number;
  uploadFileList = [];
  support_doc_list = [];
  selected_GRN_total: any;
  tagNameArr: any;

  constructor(
    fb: FormBuilder,
    private tagService: TaggingService,
    public router: Router,
    private authService: AuthenticationService,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private exceptionService: ExceptionsService,
    private AlertService: AlertService,
    private messageService: MessageService,
    private SpinnerService: NgxSpinnerService,
    private permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingsService,
    private SharedService: SharedService,
    private mat_dlg: MatDialog,
    private datePipe : DatePipe
  ) {
    super();
    this.exceptionService.getMsg().pipe(take(2)).subscribe((msg) => {
      if (msg == 'mapping') {
        this.getInvoiceFulldata('');
      }
    })
  }

  ngOnInit(): void {
    this.rejectReason = this.dataService.rejectReason;
    this.GRN_PO_Bool = this.dataService.grnWithPOBoolean;
    this.userDetails = this.authService.currentUserValue;
    this.isDesktop = this.dataService.isDesktop;
    this.documentViewBool = this.isDesktop;
    this.enable_create_grn = this.permissionService.enable_create_grn
    if (this.userDetails.user_type == 'vendor_portal') {
      this.portalName = 'vendorPortal';
    } else {
      this.portalName = 'customer'
    }
    this.initialData();
    this.readFilePath();
    this.AddPermission();
    this.isAdmin = this.dataService.isAdmin;
    this.currentTab = 'line';

  }
  getPdfBool(event) {
    this.isPdfAvailable = event;
  }
  doc_view() {
    this.showPdf = true;
    this.documentViewBool = !this.documentViewBool
  }

  idleTimer(time, str) {
    this.timer = new IdleTimer({
      timeout: time, //expired after 180 secs
      clean: str,
      onTimeout: () => {
        if (this.router.url.includes('comparision-docs')) {
          this.router.navigate([`${this.portalName}/ExceptionManagement`]);
          this.AlertService.errorObject.detail =
            'Session Expired for Editing Invoice';
          this.messageService.add(this.AlertService.errorObject);
        }
      },
    });
  }

  updateSessionTime() {
    let sessionData = {
      session_status: true,
      "client_address": JSON.parse(localStorage.getItem('userIp'))
    };
    this.exceptionService
      .updateDocumentLockInfo(JSON.stringify(sessionData))
      .subscribe((data: any) => { });
  }

  initialData() {
    this.routeIdCapture = this.activatedRoute.params.subscribe((params) => {
      this.SharedService.invoiceID = params['id'];
      this.exceptionService.invoiceID = params['id'];
      this.invoiceID = params['id'];
    });
    if (this.router.url.includes('Create_GRN_inv_list')|| this.router.url.includes('GRN_approvals')|| this.GRN_PO_Bool) {
      if (this.permissionService.GRNPageAccess == true) {
        this.grnCreateBoolean = true;
        if (this.GRN_PO_Bool) {
          this.tagService.headerName = 'Create GRN With PO';
          this.getInvoiceFulldata_po();
          this.get_PO_GRN_Lines();
        } else {
          if(this.router.url.includes('GRN_approvals')){
            this.tagService.headerName = 'GRN Approval';
            } else {
              this.tagService.headerName = 'Create GRN with invoice';
            }
          this.readGRNInvData();
          
        }
        // this.readPOLines();
        // if (this.grnCreateBoolean) {

        // }
      } else {
        alert('Sorry!, you do not have access');
        this.router.navigate(['customer/invoice/allInvoices']);
      }
    } else {
      this.getInvoiceFulldata('');
      // this.getRulesData();
      this.getPOs();
      // this.readPOLines();
      this.readLineItems();
      this.readErrorTypes();
      this.readMappingData();
      // this.getGRNtabData()
      if (this.tagService.editable == true && this.grnCreateBoolean == false) {
        this.updateSessionTime();
        this.idleTimer(180, 'Start');
        this.callSession = setTimeout(() => {
          this.updateSessionTime();
        }, 250000);
      }
    }
    this.onResize();
    this.Itype = this.tagService.type;
    this.editable = this.tagService.editable;
    this.fin_boolean = this.tagService.financeApprovePermission;
    this.submitBtn_boolean = this.tagService.submitBtnBoolean;
    this.approveBtn_boolean = this.tagService.approveBtnBoolean;
    this.headerName = this.tagService.headerName;
    this.userDetails = this.authService.currentUserValue;
    this.approvalType = this.tagService.approvalType;
    this.financeapproveDisplayBoolean =
      this.settingService.finaceApproveBoolean;
    this.subStatusId = this.dataService.subStatusId;

    // this.showInvoice = "/assets/New folder/MEHTAB 9497.pdf"
    this.lineCompareData = comaprisionLineData;
  }
  getRulesData() {
    this.exceptionService.readBatchRules().subscribe((data: any) => {
      this.givenRules = data;
    });
  }
  AddPermission() {
    if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == false &&
      this.permissionService.financeApproveBoolean == false
    ) {
      this.editPermissionBoolean = true;
    } else if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == true &&
      this.permissionService.financeApproveBoolean == false
    ) {
      this.changeApproveBoolean = true;
    } else if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == true &&
      this.permissionService.financeApproveBoolean == true
    ) {
      this.financeApproveBoolean = true;
    }
  }

  changeTab(val) {
    this.currentTab = val;
    if (val == 'vendor' || val == 'header') {
      this.showPdf = true;
      this.btnText = 'Close';
    } else {
      this.showPdf = false;
      this.btnText = 'View PDF';
    }
    // if (val == 'line') {
    //   this.lineTabBoolean = true;
    // } else {
    //   this.lineTabBoolean = false;
    // }
  }

  get_PO_GRN_Lines() {
    this.descrptonBool = true;
    this.dataService.GRN_PO_Data.forEach((ele, i) => {
      this.GRN_PO_tags.forEach(tag => {
        if (tag.TagName == 'Description') {
          tag.linedata.push({ Value: ele.Name, old_value: ele.Name, ErrorDesc: '', idDocumentLineItems: ele.LineNumber, is_mapped: '', tagName: 'Description' })
        } else if (tag.TagName == 'PO LineNumber') {
          tag.linedata.push({ Value: ele.LineNumber, ErrorDesc: '', idDocumentLineItems: ele.LineNumber, is_mapped: '', tagName: 'LineNumber' })
        } else if (tag.TagName == 'PO Qty') {
          tag.linedata.push({ Value: ele.PurchQty, ErrorDesc: '', idDocumentLineItems: ele.LineNumber, is_mapped: '', tagName: 'PO Qty' })
        } else if (tag.TagName == 'PO Balance Qty') {
          tag.linedata.push({ Value: ele.RemainInventPhysical, ErrorDesc: '', idDocumentLineItems: ele.LineNumber, is_mapped: '', tagName: 'PO Balance Qty' })
        } else if (tag.TagName == 'GRN - Quantity') {
          tag.linedata.push({ Value: ele.PurchQty, ErrorDesc: '', idDocumentLineItems: ele.LineNumber, is_mapped: '', tagName: 'Quantity' })
        } else if (tag.TagName == 'UnitPrice') {
          tag.linedata.push({ Value: ele.UnitPrice, ErrorDesc: '', idDocumentLineItems: ele.LineNumber, is_mapped: 'Price', tagName: 'UnitPrice' })
        } else if (tag.TagName == 'AmountExcTax') {
          let amount = (ele.UnitPrice *ele.PurchQty).toFixed(2)
          this.GRN_line_total = this.GRN_line_total + Number(amount)
          tag.linedata.push({ Value: (ele.UnitPrice * ele.PurchQty).toFixed(2), ErrorDesc: '', idDocumentLineItems: ele.LineNumber, is_mapped: '', tagName: 'AmountExcTax' })
        } else if (tag.TagName == 'Actions') {
          tag.linedata.push({ Value: '', ErrorDesc: '', idDocumentLineItems: ele.LineNumber, is_mapped: '', tagName: 'Actions' })
        }
      })
    })
    this.lineDisplayData = this.GRN_PO_tags;
    let arr = this.GRN_PO_tags;
    setTimeout(() => {
      arr.forEach((ele1) => {
        if (ele1.TagName == 'GRN - Quantity' || ele1.TagName == 'Description' || ele1.TagName == 'UnitPrice') {
          this.GRNObject.push(ele1.linedata);
        }
        if (ele1.TagName == 'GRN - Quantity') {
          ele1.linedata?.forEach((el) => {
            el.is_quantity = true;
          });
        }
        this.GRNObject = [].concat(...this.GRNObject);
      });
      this.GRNObject.forEach((val) => {
        if (!val.old_value) {
          val.old_value = val.Value;
        }
      });
    }, 100);
    this.grnLineCount = this.lineDisplayData[0]?.linedata;
    this.isGRNDataLoaded = true;
  }

  getInvoiceFulldata_po() {
    this.SpinnerService.show();
    this.inputDisplayArray = [];
    this.lineData = [];
    this.SharedService.getInvoiceInfo().subscribe(
      (data: any) => {
        const pushedArrayHeader = [];
        data.ok.headerdata.forEach((element) => {
          this.mergedArray = {
            ...element.DocumentData,
            ...element.DocumentTagDef,
          };
          this.mergedArray.DocumentUpdates = element.DocumentUpdates;
          pushedArrayHeader.push(this.mergedArray);
        });
        this.inputData = pushedArrayHeader;
        let inv_num_data: any = this.inputData.filter((val) => {
          return (val.TagLabel == 'InvoiceId') || (val.TagLabel == 'bill_number');
        });
        this.invoiceNumber = inv_num_data[0]?.Value;
        let po_num_data = this.inputData.filter((val) => {
          return (val.TagLabel == 'PurchaseOrder' || val.TagLabel ==  'PurchId');
        });
        this.po_num = po_num_data[0]?.Value;
        this.getPODocId(this.po_num);
        if (data.ok.vendordata) {
          this.vendorData = {
            ...data.ok.vendordata[0].Vendor,
            ...data.ok.vendordata[0].VendorAccount,
            ...data.ok.vendordata[0].VendorUser,
          };
          this.vendorName = this.vendorData['VendorName'];
        }
        this.SpinnerService.hide();
      },
      (error) => {
        this.SpinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Server error',
        });
      }
    );
  }

  getInvoiceFulldata(str) {
    this.SpinnerService.show();
    this.inputDisplayArray = [];
    this.lineData = [];

    this.exceptionService.getInvoiceInfo().subscribe(
      (data: any) => {
        // this.lineDisplayData = data.linedata.Result;
        // this.lineDisplayData.forEach((element, index, arr) => {
        //   this.lineCount = arr[0].items
        // });
        const lineData = [];
        this.lineCount = [];
      
        data.linedata.forEach((element, index, arr) => {

          let obj:any = {
            "Description" : element?.Description,
            Quantity : element?.Quantity,
            UnitPrice : element?.UnitPrice,
            _NetTotal : element?.AmountExcTax,
          }
          // if(this.editable){
          //   obj._U_Act = element.Description;
          // }
          if(element?.Unit){
            obj.Unit = element?.Unit;
          }
          if(element?.Discount){
            obj.Discount = element?.Discount;
          }
          lineData.push(obj)
        });
        this.lineDisplayData = lineData;
        if(this.lineDisplayData?.length>0){
          this.tagNameArr = this.lineDisplayData[0];
        }    
        // const pushedArrayHeader = [];
        // data.headerdata.forEach((element) => {
        //   this.mergedArray = {
        //     ...element.DocumentData,
        //     ...element.DocumentTagDef,
        //   };
        //   this.mergedArray.DocumentUpdates = element.DocumentUpdates;
        //   pushedArrayHeader.push(this.mergedArray);
        // });
        this.inputData =  data?.headerdata;
        this.invoiceNumber = this.inputData?.InvoiceId?.Value;
        this.po_num = this.inputData?.PurchaseOrder?.Value;
        this.invoice_subTotal = this.inputData?.SubTotal?.Value;
        // this.inputData.map((val) => {
        //   if(val.TagLabel == 'SubTotal'){
        //     this.invoice_subTotal = val?.Value;
        //   } else if(val.TagLabel == 'PurchaseOrder'){
        //     this.po_num = val?.Value;
        //   } else if(val.TagLabel == 'InvoiceId'){
        //     this.invoiceNumber = val?.Value;
        //   };
        // });
        this.getPODocId(this.po_num);
        this.getGRNnumbers(this.po_num);
        this.vendorData = {
          ...data.Vendordata[0].Vendor,
          ...data.Vendordata[0].VendorAccount,
          ...data.Vendordata[0].VendorUser,
        };
        this.vendorAcId = this.vendorData['idVendorAccount'];
        this.vendorName = this.vendorData['VendorName'];
        this.vendorId = this.vendorData['idVendor'];
        // this.selectedRule = data.ruledata[0].Name;
        if(str != 'batch'){
          // setTimeout(() => {
            this.SpinnerService.hide();
          // }, 4000);
        }
      },
      (error) => {
        this.SpinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Server error',
        });
      }
    );
  }

  getPOs() {
    this.exceptionService.getInvoicePOs().subscribe(((data: any) => {
      this.poList = data;
    }))
  }

  readGRNInvData() {
    this.SharedService.readReadyGRNInvData().subscribe(
      (data: any) => {
        this.lineDisplayData = data.ok?.linedata;
        this.grnLineCount = this.lineDisplayData[0]?.linedata;
        let dummyLineArray = this.lineDisplayData;
        dummyLineArray.forEach((ele, i, array) => {
          if (ele.TagName == 'Quantity') {
            ele.TagName = 'Inv - Quantity';
            ele.linedata?.forEach((ele2, index) => {
              this.validatePOInvUnit.push({ invoice_itemcode: ele2.invoice_itemcode })
              if (ele.linedata?.length <= ele.grndata?.length) {
                ele.grndata?.forEach((ele3) => {
                  ele.grndata[index].old_value = ele2.Value;
                });
              }
            });
          } else if (ele.TagName == 'UnitPrice') {
            ele.TagName = 'Inv - UnitPrice';
          } else if (ele.TagName == 'Description' || ele.TagName == 'Name') {
            if (ele.linedata?.length > 0) {
              this.descrptonBool = true;
            }
          }
          if (ele.TagName == 'AmountExcTax') {
            ele.TagName = 'Inv - AmountExcTax';
            ele.linedata.forEach(v=>{
              this.GRN_line_total = this.GRN_line_total + Number(v.Value)
            })
          }

          setTimeout(() => {
            if (
              ele.TagName == 'Inv - Quantity' &&
              (ele.grndata == null || ele.grndata.length == 0)
            ) {
              array.splice(2, 0, {
                TagName: 'GRN - Quantity',
                linedata: ele.linedata,
              });
              array.splice(7, 0, {
                TagName: 'Comments',
                linedata: ele.linedata,
              });
            } else if (
              ele.TagName == 'Inv - Quantity' &&
              ele.grndata != null &&
              ele.grndata &&
              ele.grndata.length != 0
            ) {
              array.splice(2, 0, {
                TagName: 'GRN - Quantity',
                linedata: ele.grndata,
              });
              array.splice(7, 0, {
                TagName: 'Comments',
                linedata: ele.grndata,
              });
              let poQty = [];
              let poBalQty = [];
              ele.podata.forEach((v) => {
                if (v.TagName == 'PurchQty') {
                  poQty.push(v);
                } else if (v.TagName == 'RemainInventPhysical') {
                  poBalQty.push(v);
                } 
              });
              if (poQty.length > 0) {
                array.splice(8, 0, { TagName: 'PO quantity', linedata: poQty });
                // array.splice(9, 0, {
                //   TagName: 'Linenumber',
                //   linedata: lineNumber,
                // });
              }
            } else if (
              ele.TagName == 'Inv - UnitPrice' &&
              (ele.grndata == null || ele.grndata.length == 0)
            ) {
              array.splice(4, 0, {
                TagName: 'GRN - UnitPrice',
                linedata: ele.linedata,
              });
            } else if (
              ele.TagName == 'Inv - UnitPrice' &&
              ele.grndata != null &&
              ele.grndata &&
              ele.grndata.length != 0
            ) {
              array.splice(4, 0, {
                TagName: 'GRN - UnitPrice',
                linedata: ele.grndata,
              });
            } else if (
              ele.TagName == 'Inv - AmountExcTax' &&
              (ele.grndata == null || ele.grndata.length == 0)
            ) {
              array.splice(6, 0, {
                TagName: 'GRN - AmountExcTax',
                linedata: ele.linedata,
              });
            } else if (
              ele.TagName == 'Inv - AmountExcTax' &&
              ele.grndata != null &&
              ele.grndata &&
              ele.grndata.length != 0
            ) {
              array.splice(6, 0, {
                TagName: 'GRN - AmountExcTax',
                linedata: ele.grndata,
              });
            }
          }, 10);
        });
        this.lineDisplayData = dummyLineArray;

        setTimeout(() => {
          this.lineDisplayData = this.lineDisplayData.filter((v) => {
            return !(
              v.TagName == 'Inv - AmountExcTax'
            );
          });
        }, 100);
        let arr = dummyLineArray;
        setTimeout(() => {
          arr.forEach((ele1) => {
            if (ele1.TagName.includes('GRN') || ele1.TagName == 'Description') {
              this.GRNObject.push(ele1.linedata);
            }
            if (ele1.TagName == 'GRN - Quantity') {
              ele1.linedata?.forEach((el) => {
                el.is_quantity = true;
              });
            }
            this.GRNObject = [].concat(...this.GRNObject);
          });
          this.GRNObject.forEach((val) => {
            if (!val.old_value) {
              val.old_value = val.Value;
            }
          });
        }, 100);

        const pushedArrayHeader = [];
        data.ok.headerdata.forEach((element) => {
          this.mergedArray = {
            ...element.DocumentData,
            ...element.DocumentTagDef,
          };
          this.mergedArray.DocumentUpdates = element.DocumentUpdates;
          pushedArrayHeader.push(this.mergedArray);
        });
        this.inputData = pushedArrayHeader;
        // let inv_num_data: any = this.inputData.filter(val => {
        //   return val.TagLabel == 'InvoiceId';
        // })
        // this.invoiceNumber = inv_num_data[0]?.Value;
        // let po_num_data = this.inputData.filter((val) => {
        //   return (val.TagLabel == 'PurchaseOrder');
        // });
        
        this.inputData.map((val) => {
          if(val.TagLabel == 'SubTotal'){
            this.invoice_subTotal = val?.Value;
          } else if(val.TagLabel == 'PurchaseOrder' || val.TagLabel == 'PurchId'){
            this.po_num = val?.Value;
          } else if(val.TagLabel == 'InvoiceId'){
            this.invoiceNumber = val?.Value;
          };
        });
        // this.po_num = po_num_data[0]?.Value;
        this.getPODocId(this.po_num);
        this.vendorData = {
          ...data.ok.vendordata[0].Vendor,
          ...data.ok.vendordata[0].VendorAccount,
        };
        this.vendorAcId = this.vendorData['idVendorAccount'];
        this.vendorName = this.vendorData['VendorName'];
        // this.selectedRule = data.ok.ruledata[0].Name;
        // this.poList = data.all_pos;
        if(this.router.url.includes('GRN_approvals') ){
          let index = this.lineDisplayData.findIndex(tag=> tag.TagName == 'Inv - Quantity');
          this.lineDisplayData.splice(index,1)
        }
        this.isGRNDataLoaded = true;
        this.SpinnerService.hide();
      },
      (error) => {
        this.SpinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Server error',
        });
      }
    );
  }

  readFilePath() {
    this.showInvoice = '';
    // this.SpinnerService.show();
    this.exceptionService.readFilePath().subscribe(
      (data: any) => {
        this.content_type = data?.content_type;
        if (data.filepath && data.content_type == 'application/pdf') {
          this.isPdfAvailable = false;
          this.isImgBoolean = false;
          this.byteArray = new Uint8Array(
            atob(data.filepath)
              .split('')
              .map((char) => char.charCodeAt(0))
          );
          this.showInvoice = window.URL.createObjectURL(
            new Blob([this.byteArray], { type: 'application/pdf' })
          );
        } else if (data.content_type == 'image/jpg' || data.content_type == 'image/png') {
          this.isPdfAvailable = false;
          this.isImgBoolean = true;
          this.byteArray = new Uint8Array(
            atob(data.filepath)
              .split('')
              .map((char) => char.charCodeAt(0))
          );
          this.showInvoice = window.URL.createObjectURL(
            new Blob([this.byteArray], { type: data.content_type })
          );
          // this.loadImage();
        } else {
          this.isPdfAvailable = true;
          this.showInvoice = '';
        }
        // this.SpinnerService.hide();
      },
      (error) => {
        this.SpinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Server error',
        });
      }
    );
  }

  DownloadPDF() {
    let extension;
    if (this.content_type == 'application/pdf') {
      extension = '.pdf';
    } else if (this.content_type == 'image/jpg') {
      extension = '.jpg';
    } else if (this.content_type == 'image/png') {
      extension = '.png';
    }
    fileSaver.saveAs(this.showInvoice, `${this.vendorName}_${this.invoiceNumber}${extension}`);
  }

  loadImage() {
    if (this.isImgBoolean == true) {
      setTimeout(() => {
        this.zoomVal = 1;
        (<HTMLDivElement>document.getElementById('parentDiv')).style.transform =
          'scale(' + this.zoomVal + ')';

        const canvas = <HTMLCanvasElement>document.getElementById('canvas1');
        canvas.height = window.innerHeight;
        canvas.width = window.innerWidth;
        const ctx = canvas.getContext('2d');
        let image = new Image();
        image.src = this.showInvoice;
        image.onload = function () {
          // Calculate the aspect ratio of the image
          const imageAspectRatio = image.width / image.height;
          // Calculate the aspect ratio of the canvas
          const canvasAspectRatio = canvas.width / canvas.height;

          // Set the dimensions of the image to fit the canvas while maintaining the aspect ratio
          let imageWidth, imageHeight;
          if (imageAspectRatio > canvasAspectRatio) {
            // The image is wider than the canvas, so set the width of the image to the width of the canvas
            // and scale the height accordingly
            imageWidth = canvas.width;
            imageHeight = imageWidth / imageAspectRatio;
          } else {
            // The image is taller than the canvas, so set the height of the image to the height of the canvas
            // and scale the width accordingly
            imageHeight = canvas.height;
            imageWidth = imageHeight * imageAspectRatio;
          }

          // Draw the image on the canvas
          ctx.drawImage(image, 0, 0, imageWidth, imageHeight);
        };


      }, 50);
    }
  }

  onChangeValue(key, value, data) {
    if (key == 'InvoiceTotal' || key == 'SubTotal') { 
      if (value == '' || isNaN(+value)) {
        this.isAmtStr = true ;
      } else {
        this.isAmtStr = false ;
      }
    }
    let updateValue = {
      documentDataID: data.idDocumentData,
      OldValue: data.Value || '',
      NewValue: value,
    };
    this.updateInvoiceData.push(updateValue);
  }
  onChangeLineValue(key,value, data) {
    if (key == 'Quantity' || key == 'UnitPrice' || key == 'AmountExcTax') { 
      if (value == '' || isNaN(+value)) {
        this.isAmtStr = true ;
      } else {
        this.isAmtStr = false ;
      }
    } else if(key == 'Description') {
      if(value == ''){
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
      }
    }
    let updateValue = {
      documentLineItemID: data.idDocumentLineItems,
      OldValue: data.Value || '',
      NewValue: value,
    };
    this.updateInvoiceData.push(updateValue);
  }

  saveChanges() {
    if(!this.isAmtStr && !this.isEmpty){
      if (this.updateInvoiceData.length != 0) {
        this.SharedService.updateInvoiceDetails(
          JSON.stringify(this.updateInvoiceData)
        ).subscribe(
          (data: any) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Saved',
              detail: 'Changes saved successfully',
            });

            this.updateInvoiceData = [];
          },
          (err) => {
            this.updateInvoiceData = [];
            if(err.status == 403){
              this.AlertService.errorObject.detail = 'Sorry, Editing is locked at this stage.';
            } else {
              this.AlertService.errorObject.detail = 'Server error or Please check the data.';
            }
            this.messageService.add(this.AlertService.errorObject);
          }
        );
      }
    } else {
      this.updateInvoiceData = [];
      let err = ''
      if(this.isAmtStr){
        err = 'Alphabets not allowed in the Quantity and Amount Fields.';
      } else if(this.isEmpty){
        err = '"Description" field cannot be empty.';
      }

      this.messageService.add({
        severity: 'error',
        summary: 'error',
        detail: err,
      });
      // this.errorTriger('Strings are not allowed in the amount and quantity fields.');
    }
  }
  onSubmitData() {
    // this.SpinnerService.show();
    // this.SharedService.updateInvoiceDetails(JSON.stringify(this.updateInvoiceData)).subscribe((data: any) => {
    //   console.log(data);
    //   if (data.result == 'success') {
    //     this.messageService.add({
    //       severity: "info",
    //       summary: "Updated",
    //       detail: "Updated Successfully"
    //     });
    //     this.getInvoiceFulldata();
    //   } else {
    //     this.messageService.add({
    //       severity: "error",
    //       summary: "error",
    //       detail: "Something went wrong"
    //     });
    //   }
    //   this.updateInvoiceData = [];
    //   this.SpinnerService.hide();
    // })
  }

  drawrectangleonHighlight(index) {
    // var rect = new fabric.Rect({
    //   left: 100,
    //   top: 50,
    //   fill: 'rgba(255,0,0,0.5)',
    //   width: 100,
    //   height: 30,
    //   selectable: false,
    //   lockMovementX: true,
    //   lockMovementY: true,
    //   lockRotation: true,
    //   transparentCorners: true,
    //   hasControls: false,
    // });

    // this.canvas[index].add(rect);
    // this.canvas[index].setActiveObject(rect);
    // document.getElementById(index + 1).scrollIntoView();
  }

  zoomin() {
    this.zoomVal = this.zoomVal + 0.2;
    this.zoomX = this.zoomX + 0.05;
    if (this.zoomVal >= 2.0 && this.zoomX >= 2.0) {
      this.zoomVal = 1;
      this.zoomX = 1;
    }
    (<HTMLDivElement>document.getElementById('canvas1')).style.transform = `scale(${this.zoomX},${this.zoomVal})`;
  }

  zoomout(index) {
    this.zoomVal = this.zoomVal - 0.2;
    this.zoomX = this.zoomX - 0.05;
    if (this.zoomVal <= 0.5 && this.zoomX <= 0.8) {
      this.zoomVal = 1;
      this.zoomX = 1;
    }
    (<HTMLDivElement>document.getElementById('canvas1')).style.transform = `scale(${this.zoomX},${this.zoomVal})`;
  }

  removeEvents(index) {
    this.canvas[index].off('mouse:down');
    this.canvas[index].off('mouse:up');
    this.canvas[index].off('mouse:move');
  }

  panning(index) {
    // this.removeEvents(index);
    // let panning = false;
    // let selectable;
    // this.canvas[index].on('mouse:up', (e) => {
    //   panning = false;
    // });

    // this.canvas[index].on('mouse:down', (e) => {
    //   panning = true;
    //   selectable = false;
    // });
    // this.canvas[index].on('mouse:move', (e) => {
    //   if (panning && e && e.e) {
    //     selectable = false;
    //     var units = 10;
    //     var delta = new fabric.Point(e.e.movementX, e.e.movementY);
    //     this.canvas[index].relativePan(delta);
    //   }
    // });
  }

  addVendorDetails() {
  }
  onVerify(e) {
  }
  submitChanges() {
    // if (this.userDetails.user_type == 'customer_portal') {
    //   let submitData = {
    //     "documentdescription": " "
    //   }
    //   this.SpinnerService.show();
    //   this.SharedService.submitChangesInvoice(JSON.stringify(submitData)).subscribe((data: any) => {
    //     this.dataService.invoiceLoadedData = [];
    //     if (data.result) {
    //       this.messageService.add({
    //         severity: "success",
    //         summary: "Updated",
    //         detail: "Updated Successfully"
    //       });
    //       this.SpinnerService.hide();
    //       setTimeout(() => {
    //         this._location.back()
    //       }, 1000);
    //     }
    //   }, error => {
    //     this.messageService.add({
    //       severity: "error",
    //       summary: "error",
    //       detail: error.error
    //     });
    //     this.SpinnerService.hide();
    //   })
    // } else if (this.userDetails.user_type == 'vendor_portal') {
    //   this.SharedService.vendorSubmit().subscribe((data: any) => {
    //     console.log(data);
    //     this.messageService.add({
    //       severity: "success",
    //       summary: "Uploaded",
    //       detail: "Uploaded to serina successfully"
    //     });
    //     setTimeout(() => {
    //       this.router.navigate(['vendorPortal/invoice/allInvoices']);
    //     }, 1000);
    //   }, error => {
    //     this.messageService.add({
    //       severity: "error",
    //       summary: "error",
    //       detail: error.statusText
    //     });
    //   })
    // }
  }

  approveChangesManual() {
    this.exceptionService.send_manual_approval().subscribe(
      (data: any) => {
        this.AlertService.addObject.detail =
          'Send to Manual approval successfully';
        this.messageService.add(this.AlertService.addObject);
        setTimeout(() => {
          this._location.back();
        }, 2000);
      },
      (error) => {
        this.AlertService.errorObject.detail = error.statusText;
        this.messageService.add(this.AlertService.errorObject);
      }
    );
  }

  approveChangesBatch() {
    this.getInvoiceFulldata('batch');
    setTimeout(() => {
      let count = 0;
      let errorType: string;
      let errorTypeHead: string;
      let errorTypeLine: string;
      for(const data in this.inputData){
        if (data == 'InvoiceTotal' || data == 'SubTotal') {
          if (this.inputData[data].Value == '' || isNaN(+this.inputData[data].Value)) {
            count++;
            errorTypeHead = 'AmountHeader';
          }
        } else if (data == 'PurchaseOrder' || data == 'InvoiceDate' || data == 'InvoiceId') {
          if (this.inputData[data]?.Value == '') {
            count++;
            errorType = 'emptyHeader';
          }
        }
      }

      this.lineDisplayData?.forEach((element) => {
        for(const tag in element){
          if ( tag == 'UnitPrice' || tag == 'AmountExcTax' || tag == 'Amount') {
            if(element[tag]?.Value == '' || isNaN(element[tag]?.Value)){
              count++;
              errorTypeLine = 'AmountLine';
            }
          } else if(tag == 'Quantity'){
            if(element[tag]?.Value == 0){
              count++;
              errorTypeLine = 'quntity';
            }
          }
        }

      });
      if (count == 0) {
        this.sendToBatch();
      } else {
        /* Error reponse starts*/
        if (errorTypeHead == 'AmountHeader') {
          setTimeout(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail:
                "Please verify the 'Sub-Total' and 'Invoice-Total' on the Header",
            });
          }, 50);
        }
        if (errorType == 'emptyHeader') {
          this.AlertService.errorObject.detail =
            'Please Check the PO Number, Invoice Date, and Invoice-Id fields on the header';
          this.messageService.add(this.AlertService.errorObject);
        }
        if (errorTypeLine == 'AmountLine') {
          setTimeout(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail:
                'Please verify the Amount, Quantity, Unit price and Amount-Excluding-Tax on the Line details',
            });
          }, 10);
        } else if (errorTypeLine == 'quntity') {
          setTimeout(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail:
                "Please check the 'Quantity' in the Line details",
            });
          }, 10);
        }
        this.SpinnerService.hide();
        /* Error reponse end*/
      }
    }, 2000);
  }

  sendToBatch() {
    this.SpinnerService.show()
    this.exceptionService.send_batch_approval().subscribe(
      (data: any) => {
        this.dataService.invoiceLoadedData = [];
        // this.SpinnerService.hide();
        this.AlertService.addObject.detail = 'Sent to Batch Successfully!';
        this.AlertService.addObject.summary = 'sent';
        this.messageService.add(this.AlertService.addObject);
        this.syncBatch();

      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Server error',
        });
      }
    );
  }
  syncBatch(){
    this.SpinnerService.show();
    this.SharedService.syncBatchTrigger(`?re_upload=false`).subscribe((data: any) => {
      this.headerpop = 'Batch Progress';
      this.p_width = '350px';
      this.progressDailogBool = true;
      this.GRNDialogBool = false;
      this.batchData = data[this.invoiceID]?.complete_status;
      let last_msg = this.batchData[this.batchData.length - 1].msg;
      this.isBatchFailed = false;
      if( last_msg == 'Batch ran to an Exception!' || last_msg == 'Matching Failed - Batch Failed' && this.batch_count <=2){
        this.batch_count++;
        this.isBatchFailed = true;
      }
      if(!(this.batch_count <=2)){
        this.AlertService.errorObject.detail = "Dear User, Kindly check with Serina's support team regarding this invoice.";
        this.messageService.add(this.AlertService.errorObject);
        // setTimeout(() => {
        //   this.router.navigate([`${this.portalName}/ExceptionManagement`])
        // }, 2000);
      }
      this.SpinnerService.hide();
    },err=>{
      this.SpinnerService.hide();
    });
  }

  routeToMapping() {
    this.exceptionService.invoiceID = this.invoiceID;
    this.tagService.editable = true;
    this.tagService.submitBtnBoolean = true;
    this.tagService.headerName = 'Edit Invoice';
    let sub_status = null;
    this.refreshPOBool = false;
    for (const el of this.batchData) {
      if (el.status == 0) {
        sub_status = el.sub_status;
      }
    };
    if(!sub_status){
      sub_status = this.batchData[this.batchData.length-1].sub_status;
    }
    this.subStatusId = sub_status;
    this.dataService.subStatusId = sub_status;
    if (this.portalName == 'vendorPortal') {
      if (sub_status == 8 ||
        sub_status == 16 ||
        sub_status == 18 ||
        sub_status == 19 ||
        sub_status == 33 ||
        sub_status == 21 ||
        sub_status == 27) {
          if(sub_status == 18){
            this.AlertService.updateObject.detail = "Invoice Total and 'Sub-Total+Tax' Mismatch Identified. Kindly check Entry.";
          } else if(sub_status == 19){
            this.AlertService.updateObject.detail = 'Dear User, Sub total is not matching with the invoice lines total.';
          } else {
            this.getInvoiceFulldata('');
            this.AlertService.updateObject.detail = 'Please check the values in invoice.';
          }
        this.AlertService.updateObject.summary = 'Suggestion';
        this.messageService.add(this.AlertService.updateObject);
        this.refreshPOBool = true;
      } else {
        this.router.navigate([`${this.portalName}/invoice/allInvoices`]);
      }
    } else {
      if (sub_status == 8 ||
        sub_status == 16 ||
        sub_status == 17 ||
        sub_status == 18 ||
        sub_status == 19 ||
        sub_status == 33 ||
        sub_status == 21 ||
        sub_status == 27 ||
        sub_status == 75) {
        this.AlertService.updateObject.summary = 'Suggestion';
        if(sub_status == 18){
          this.AlertService.updateObject.detail = "Invoice Total and 'Sub-Total+Tax' Mismatch Identified. Kindly check Entry.";
        } else if(sub_status == 19){
          this.AlertService.updateObject.detail = 'Dear User, Sub total is not matching with the invoice lines total.';
        } else {
          this.getInvoiceFulldata('');
          this.AlertService.updateObject.detail = 'Please check the values in invoice.';
        }
        this.messageService.add(this.AlertService.updateObject);
        this.refreshPOBool = true;
      } else if (sub_status == 34) {
        this.refreshPOBool = true;
        this.AlertService.updateObject.summary = 'Suggestion';
        this.AlertService.updateObject.detail = "Please compare the PO lines with invoices. We generally recommend the 'PO flip' method to resolve issues of this type. ";
        this.messageService.add(this.AlertService.updateObject)
      } else if (sub_status == 7 || sub_status == 23 || sub_status == 10 || sub_status == 35 || sub_status == 23 ) {
        this.router.navigate([`${this.portalName}/ExceptionManagement`]);
      } else {
        this.router.navigate([`${this.portalName}/invoice/allInvoices`]);
      }
    }

    this.progressDailogBool = false;
  }

  financeApprove() {
    this.SharedService.financeApprovalPermission().subscribe(
      (data: any) => {
        this.dataService.invoiceLoadedData = [];
        this.messageService.add({
          severity: 'success',
          summary: 'Approved',
          detail: data.result,
        });
        setTimeout(() => {
          this._location.back();
        }, 1000);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: error.statusText,
        });
      }
    );
  }

  open_modal() {
    this.displayRuleDialog = true;
    this.save_rule_boolean = true;
  }

  send_review_modal() {
    this.displayRuleDialog = true;
    this.save_rule_boolean = false;
  }

  save_rule() {
    this.selectedRule = this.SelectRuleOption.value['Name'];
    this.selectedRuleID = this.SelectRuleOption.value['idDocumentRules'];
    this.displayRuleDialog = false;
  }

  sendReview() {
    this.selectedRule = this.SelectRuleOption.value['Name'];
    this.selectedRuleID = this.SelectRuleOption.value['idDocumentRules'];
    this.exceptionService
      .send_batch_approval_review(this.selectedRuleID)
      .subscribe(
        (data: any) => {
          this.AlertService.addObject.detail =
            'Send to Batch review successfully';
          this.messageService.add(this.AlertService.addObject);
          this.displayRuleDialog = false;
          setTimeout(() => {
            this._location.back();
          }, 2000);
        },
        (error) => {
          this.AlertService.errorObject.detail = error.statusText;
          this.messageService.add(this.AlertService.errorObject);
        }
      );
  }

  reviewManualApprove() {
    if (confirm(`Are you sure you want to send for Manual approval?`)) {
      this.exceptionService.send_review_manual().subscribe(
        (data: any) => {
          this.AlertService.addObject.detail =
            'Send to Manual approval review successfully';
          this.messageService.add(this.AlertService.addObject);
          setTimeout(() => {
            this._location.back();
          }, 2000);
        },
        (error) => {
          this.AlertService.errorObject.detail = error.statusText;
          this.messageService.add(this.AlertService.errorObject);
        }
      );
    }
  }

  backToInvoice() {
    if (
      !this.router.url.includes('vendorUpload') ||
      !this.router.url.includes('CustomerUpload')
    ) {
      this._location.back();
    } else if (
      this.router.url.includes('vendorUpload') &&
      this.router.url.includes('CustomerUpload') &&
      this.submitBtn_boolean == true
    ) {
      if (
        confirm(
          ` Are you sure you want cancel process ? \n if you click OK you will lost your invoice meta data.`
        )
      ) {
        this._location.back();
      }
    } else {
      this._location.back();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerHeight = window.innerHeight;
    if (this.innerHeight > 550 && this.innerHeight < 649) {
      this.InvoiceHeight = 500;
    } else if (this.innerHeight > 650 && this.innerHeight < 700) {
      this.InvoiceHeight = 560;
    } else if (this.innerHeight > 750) {
      this.InvoiceHeight = 660;
    }
  }
  zoomIn() {
    this.zoomdata = this.zoomdata + 0.1;
  }
  zoomOut() {
    this.zoomdata = this.zoomdata - 0.1;
  }
  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }
  textLayerRendered(e: CustomEvent) { }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }
  selectedText(): void { }

  search(stringToSearch: string) {
    this.pdfViewer.pdfFindController.executeCommand('find', {
      caseSensitive: false,
      findPrevious: undefined,
      highlightAll: true,
      phraseSearch: true,
      query: stringToSearch,
    });
  }
  hightlight(val) {
    var pageno = parseInt('1');
    var pageView = this.pdfViewer.pdfViewer._pages[pageno - 1];
    //datas - array returning from server contains synctex output values
    var left = parseInt('530px');
    var top = parseInt('660px');
    var width = parseInt('50px');
    var height = parseInt('20px');
    //recalculating top value
    top = pageView.viewport.viewBox[3] - top;
    var valueArray = [left, top, left + width, top + height];
    let rect = pageView.viewport.convertToViewportRectangle(valueArray);
    // rect       = PDFJS.disableTextLayer.normalizeRect(rect);
    var x = Math.min(rect[0], rect[2]),
      width = Math.abs(rect[0] - rect[2]);
    var y = Math.min(rect[1], rect[3]),
      height = Math.abs(rect[1] - rect[3]);
    const element = document.createElement('div');
    element.setAttribute('class', 'overlay-div-synctex');
    element.style.left = x + 'px';
    element.style.top = y + 'px';
    element.style.width = width + 'px';
    element.style.height = height + 'px';
    element.style.position = 'absolute';
    element.style.backgroundColor = 'rgba(200,0,0,0.5)';
    $('*[data-page-number="' + pageno + '"]').append(element);
    this.pdfviewer.pdfViewer._scrollIntoView({
      pageDiv: pageView.div,
    });
  }

  onClick(e) {
    const textLayer = document.getElementsByClassName('TextLayer');
    const x =
      window.getSelection().getRangeAt(0).getClientRects()[0].left -
      textLayer[0].getBoundingClientRect().left;
    const y =
      window.getSelection().getRangeAt(0).getClientRects()[0].top -
      textLayer[0].getBoundingClientRect().top;
  }

  viewPdf() {
    this.showPdf = !this.showPdf;
    if (this.showPdf != true) {
      this.btnText = 'View PDF';
    } else {
      this.btnText = 'Close';
    }
    if (this.isImgBoolean == true) {
      this.loadImage();
    }
  }
  rotate(angle: number) {
    this.rotation += angle;
  }

  filterPO(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.poList.length; i++) {
      let country = this.poList[i];
      if (
        country.PODocumentID.toLowerCase().indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(country);
      }
    }
    this.filteredPO = filtered;
  }

  onSelectPO(value) {
    if (confirm(`Are you sure you want to change PO Number?`)) {
      this.exceptionService.updatePONumber(value.PODocumentID).subscribe(
        (data: any) => {
          this.AlertService.addObject.detail = 'PO Number updated successfully';
          this.messageService.add(this.AlertService.addObject);
          this.getInvoiceFulldata('');
        },
        (error) => {
          this.AlertService.errorObject.detail = error.statusText;
          this.messageService.add(this.AlertService.errorObject);
        }
      );
    }
  }

  readLineItems() {
    this.exceptionService.readLineItems().subscribe((data: any) => {
      this.lineItems = data.description;
    });
  }
  filterPOLine(event){
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.lineItems.length; i++) {
      let item = this.lineItems[i];
      if (
        item.Value.toLowerCase().includes(query.toLowerCase())
      ) {
        filtered.push(item);
      }
    }
    this.filteredPOLine = filtered;
  }
  readErrorTypes() {
    this.exceptionService.readErrorTypes().subscribe((data: any) => {
      this.givenErrors = data.description;
    });
  }



  lineMapping(val, el, val1, code) {
    let itemCodeArray = [];
    let presetBoolean: boolean = false;

    this.inv_itemcode = val;
    this.po_itemcode = el;
    if (itemCodeArray.length > 1) {
      presetBoolean = itemCodeArray.includes(el);
    }
    if (this.mappedData?.length > 0) {
      let presetArray = this.mappedData?.filter((ele1) => {
        return ele1.ItemMetaData?.itemcode == el;
      });
      if (presetArray.length > 0) {
        presetBoolean = true;
      }
    }
    if (presetBoolean) {
      if (confirm('Lineitem already mapped, you want to change it again')) {
        this.displayErrorDialog = true;
      }
    } else {
      itemCodeArray.push(el);
      this.displayErrorDialog = true;
    }
  }

  cancelSelectErrorRule() {
    this.displayErrorDialog = false;
  }

  updateLine() {
    this.exceptionService
      .updateLineItems(
        this.inv_itemcode,
        this.po_itemcode,
        this.SelectErrorOption,
        this.vendorAcId
      )
      .subscribe(
        (data: any) => {
          this.displayErrorDialog = false;
          this.AlertService.addObject.detail = 'Line item updated successfully';
          this.messageService.add(this.AlertService.addObject);
          this.getInvoiceFulldata('');
          this.readMappingData();
        },
        (error) => {
          this.AlertService.errorObject.detail = error.statusText;
          this.messageService.add(this.AlertService.errorObject);
          this.displayErrorDialog = false;
        }
      );
  }

  readMappingData() {
    this.exceptionService.readMappedData().subscribe((data: any) => {
      this.mappedData = data?.description;
    });
  }
  selectReason(reasn) {
    if (reasn == 'Others') {
      this.addrejectcmtBool = true;
    } else {
      this.addrejectcmtBool = false;
    }
  }

  rejectKepup(val) {
    this.rejectionComments = val;
  }

  Reject() {
    if(!this.router.url.includes('GRN_approvals')){
      let rejectionData = {
        documentdescription: this.rejectionComments,
        userAmount: 0,
      };
      this.SharedService.vendorRejectInvoice(
        JSON.stringify(rejectionData)
      ).subscribe(
        (data: any) => {
          this.dataService.invoiceLoadedData = [];
          this.messageService.add({
            severity: 'success',
            summary: 'Rejected',
            detail: 'Rejection Notification sent to Vendor',
          });
          this.displayrejectDialog = false;
          setTimeout(() => {
            this.router.navigate([`${this.portalName}/ExceptionManagement`]);
          }, 1000);
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'error',
            detail: 'Something went wrong',
          });
        }
      );
    } else {
      this.SharedService.rejectGRN().subscribe((data:any)=>{
        if(data?.status == 'success'){
          this.AlertService.addObject.detail = data.message;
          this.messageService.add(this.AlertService.addObject);
        } else {
          this.AlertService.errorObject.detail = data.message;
          this.messageService.add(this.AlertService.errorObject);
        }
        this.displayrejectDialog = false;
          setTimeout(() => {
            this.router.navigate([`${this.portalName}/GRN_approvals`]);
          }, 1000);
      },err=>{
        this.AlertService.errorObject.detail = "Server error";
        this.messageService.add(this.AlertService.errorObject);
      })
    }
  }

  onChangeGrn(lineItem, val) {
    if(this.GRN_PO_Bool){
      this.updateAmountExcTax(lineItem,val,'UnitPrice','AmountExcTax','idDocumentLineItems');
    } else {
      this.onChangeLineValue('Quantity',val,lineItem);
      this.updateAmountExcTax(lineItem,val,'GRN - UnitPrice','GRN - AmountExcTax','invoice_itemcode');
    }
  }
  updateAmountExcTax(lineItem, newQuantity: number,TagName_u,TagName_a,field) {
    if (lineItem) {
      const unitPrice = this.lineDisplayData.find(item => item.TagName == TagName_u)
        .linedata.find(data => data[field] === lineItem[field]);
      const amountExcTax = Number(unitPrice.Value) * newQuantity;
      const amountExcTaxItem = this.lineDisplayData.find(item => item.TagName == TagName_a)
        .linedata.find(data => data[field] === lineItem[field]);

      if (amountExcTaxItem) {
        amountExcTaxItem.Value = amountExcTax.toFixed(2);
        amountExcTaxItem.ErrorDesc = "Quantity changed";
      }
      this.GRN_line_total = 0;
      this.lineDisplayData.forEach(ele=>{
        if(ele.TagName == TagName_a){
          ele.linedata.forEach(v=> this.GRN_line_total = this.GRN_line_total + Number(v.Value))
        }
      })
    }
  }

  deleteGrnLine(id) {
    if (confirm('Are you sure you want to delete this line?')) {
      this.lineDisplayData = this.lineDisplayData.map(record => {
        const newLinedata = record.linedata.filter(obj => obj.idDocumentLineItems !== id);
        return { ...record, linedata: newLinedata };
      });
      this.GRN_line_total = 0;
      this.lineDisplayData.forEach(ele=>{
        if(ele.TagName == "AmountExcTax"){
          ele.linedata.forEach(v=> this.GRN_line_total = this.GRN_line_total + Number(v.Value))
        }
      })
      this.GRNObject = this.GRNObject.filter(val => {
        return val.idDocumentLineItems != id
      })
      this.grnLineCount = this.lineDisplayData[0]?.linedata;
    }
  }

  confirm_pop(grnQ, boolean, txt){
    const drf:MatDialogRef<ConfirmationComponent> = this.mat_dlg.open(ConfirmationComponent,{ 
      width : '30%',
      height: '38vh',
      hasBackdrop: false,
      data : { body: 'Kindly confirm the number of GRN lines. ',type:'confirmation'}})

      drf.afterClosed().subscribe((bool)=>{
        if(bool){
          this.onSave_submit(grnQ, boolean, txt);
        } 
      })
  }

  onSave_submit(grnQ, boolean, txt) {
    if (this.descrptonBool) {
      this.GRNObjectDuplicate = this.GRNObject;
      if (this.GRN_PO_Bool || this.router.url.includes("GRN_approvals")) {
        this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter(val => val.tagName != 'AmountExcTax');
        this.GRNObjectDuplicate.forEach((val, i) => {
          if (val.is_mapped == 'Price' && grnQ[val.idDocumentLineItems] != 0) {
            let obj = {
              Value: grnQ[val.idDocumentLineItems] * val.Value, ErrorDesc: '', idDocumentLineItems: val.idDocumentLineItems, is_mapped: '', tagName: 'AmountExcTax'
            }
            this.GRNObjectDuplicate.splice(this.GRNObjectDuplicate.length + 1, 0, obj)
          }

        })
        // this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter((val, ind, arr) => ind == arr.findIndex(v => v.idDocumentLineItems == val.idDocumentLineItems && v.tagName == val.tagName));

      } else {
        this.validateInvPOUnitPrice();
      }
      let emptyBoolean: boolean = false;
      let commentBoolean = false;
      this.GRNObjectDuplicate.forEach((ele, ind) => {
        if (ele.Value === '') {
          emptyBoolean = true;
          this.AlertService.errorObject.detail = 'Field Value cannot be empty!';
        } else if (ele.Value != ele.old_value && !this.GRN_PO_Bool) {
          if (
            ele.ErrorDesc == null ||
            ele.ErrorDesc == '' ||
            ele.ErrorDesc == 'None' ||
            ele.ErrorDesc == 'none'
          ) {
            commentBoolean = true;
            this.AlertService.errorObject.detail =
              'Kindly add Comments for lines that are Adjusted.';
          }
        } else if (ele.Value == 0) {
          if (this.GRN_PO_Bool) {
            if (ele.tagName == 'Quantity') {
              this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter(val => val.idDocumentLineItems != ele.idDocumentLineItems);
            }
            // this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter(val=> val.tagName != 'AmountExcTax');
          } else {
            commentBoolean = true;
            this.AlertService.errorObject.detail =
              'Field Value cannot be 0!';
          }

        }
      });
      if (emptyBoolean == false && commentBoolean == false) {

        if (
          boolean == true
        ) {
          if (this.GRN_PO_Bool) {
            if(this.invoiceNumber){
              this.grnDuplicateCheck();
            } else {
              this.AlertService.errorObject.detail = "Dear user, please add the invoice number.";
              this.messageService.add(this.AlertService.errorObject); 
            }

          } else {
            setTimeout(() => {
              if(this.router.url.includes('GRN_approvals')){
                this.grnDuplicateCheck();
              } else {
                this.CreateGRNAPI(boolean, txt);
              }
            }, 1000);
          }
        } else {
          if (!this.GRN_PO_Bool) {
            setTimeout(() => {
              this.CreateGRNAPI(false, 'GRN data saved successfully');
            }, 1000);
          } else {
            this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter(val => val.tagName != 'AmountExcTax');
          }
        }
      } else {
        this.messageService.add(this.AlertService.errorObject);
      }
    } else {
      this.AlertService.errorObject.detail = "Description Unavailable. Kindly Contact the Technical Team.";
      this.messageService.add(this.AlertService.errorObject);
    }
  }

  createGRNWithPO() {
    this.SpinnerService.show();
    let inv_number = '';
    if(this.invoiceNumber){
      inv_number = `&inv_num=${this.invoiceNumber}`;
    }

    this.SharedService.createGRNWithPO(inv_number,JSON.stringify(this.GRNObjectDuplicate)).subscribe((data: any) => {
      this.SpinnerService.hide();
      if(data.status == 'Posted'){
        this.AlertService.addObject.detail = data.message;
        this.messageService.add(this.AlertService.addObject);
        setTimeout(() => {
          this.router.navigate(['/customer/Create_GRN_inv_list']);
        }, 2000);
      } else {
        this.progressDailogBool = true;
        this.headerpop = 'GRN Creation Status';
        this.p_width = '350px';
        this.APIResponse = data.message;
      }
      
    }, err => {
      this.SpinnerService.hide();
      this.AlertService.errorObject.detail = "Server error";
      this.messageService.add(this.AlertService.errorObject);
    })


  }

  grnDuplicateCheck() {
    if (this.GRNObjectDuplicate.length > 0) {
      let arr = [];
      this.GRNObjectDuplicate.forEach((ele) => {
        if(this.router.url.includes("GRN_approvals")){
          if (ele.is_quantity) {
            let obj = {
              line_id: ele.invoice_itemcode,
              quantity: ele.Value
            }
            arr.push(obj)
          }
        } else {
          if (ele.tagName == 'Quantity') {
            let obj = {
              line_id: ele.idDocumentLineItems,
              quantity: ele.Value
            }
            arr.push(obj)
          }
        }

      })
      // const uniqarr = arr.filter((val,ind,arr)=> ind == arr.findIndex(v=>v.line_id == val.line_id && v.quantity == val.quantity));
      let duplicateAPI_response: string;
      let extra_param = '';
      if(this.router.url.includes('GRN_approvals')){
        extra_param = `&grn_id=${this.invoiceID}`
      } 
      this.SharedService.duplicateGRNCheck(JSON.stringify(arr),extra_param).subscribe((data: any) => {
        duplicateAPI_response = data.result;
        this.SharedService.checkGRN_PO_balance(false).subscribe((data: any) => {
          let negativeData = [];
          let negKey = {};
          let bool:boolean;
          for (let key in data?.result){
            let valuee = data.result[key];
            this.GRNObjectDuplicate.forEach((ele) => {
              if (ele.tagName == 'Quantity' && ele.idDocumentLineItems == key && (+valuee < +ele.Value)  ) {
                negKey[key] = valuee;
                negativeData.push(valuee);
              }
            })
          }
          if (negativeData.length <= 0) {    
            if (duplicateAPI_response == 'successful') {
              if(this.router.url.includes("GRN_approvals")){
                this.Approve_grn();
              } else {
                this.createGRNWithPO();
              }
              
            } else {
              this.AlertService.errorObject.detail = duplicateAPI_response;
              this.messageService.add(this.AlertService.errorObject);
            }
          } else {
            let str:string = JSON.stringify(negKey);
            this.AlertService.errorObject.detail = `Please check available quantity in the line numbers (${str})`;
            this.messageService.add(this.AlertService.errorObject);
          }
        }, err => {
          this.AlertService.errorObject.detail = "Server error";
          this.messageService.add(this.AlertService.errorObject);
        })
      }, err => {
        this.AlertService.errorObject.detail = "Server error";
        this.messageService.add(this.AlertService.errorObject);
      })
    } else {
      alert('There are no lines to create GRN, if you are able to see the lines then please check the quantity');
      this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter(val => val.tagName != 'AmountExcTax');
    }
  }

  CreateGRNAPI(boolean, txt) {
    if (this.validateUnitpriceBool) {
      if (confirm("Invoice 'unit-price' is not matching with PO. Do you want to proceed?")) {
        this.grnAPICall(boolean, txt);
      }
    } else {
      this.grnAPICall(boolean, txt);
    }

  }

  grnAPICall(boolean, txt) {
    this.SpinnerService.show();
    this.SharedService.saveGRNData(
      boolean,
      JSON.stringify(this.GRNObject)
    ).subscribe(
      (data: any) => {
        this.SpinnerService.hide();
        if(data.status == 'Posted'){
          this.AlertService.addObject.detail = data.message;
          this.messageService.add(this.AlertService.addObject);
          setTimeout(() => {
            this.router.navigate(['/customer/Create_GRN_inv_list']);
          }, 2000);
        } else {
          this.progressDailogBool = true;
          this.headerpop = 'GRN Creation Status';
          this.p_width = '350px';
          this.APIResponse = data.message;
        }

        // if (boolean == true) {
        //   setTimeout(() => {
        //     this.router.navigate(['/customer/Create_GRN_inv_list']);
        //   }, 3000);
        // }
      },
      (error) => {
        this.SpinnerService.hide();
        if (error.status == 403) {
          this.progressDailogBool = true;
          this.headerpop = 'GRN Creation Status';
          this.p_width = '350px';
          this.APIResponse = error.error.Error;
          this.AlertService.errorObject.detail = error.error.Error;
        } else {
          this.AlertService.errorObject.detail = 'Server error';
        }
        this.messageService.add(this.AlertService.errorObject);
      }
    );
  }

  validateInvPOUnitPrice() {
    this.SharedService.validateUnitprice(JSON.stringify(this.validatePOInvUnit)).subscribe((data: any) => {
      if (data.result.length > 0) {
        this.validateUnitpriceBool = true;
      } else {
        this.validateUnitpriceBool = false;
      }
    })
  }
  async open_dialog_comp(str) {
    // let rejObj = {
    //   sendor: '',
    //   rejectTxt: this.rejectionComments
    // }
    let w = '70%';
    let h = '80vh';
    let response;
    if (str == 'Amend') {
      this.displayrejectDialog = false;
      w = '40%';
      h = '40vh';
    } else if (str == 'flip line') {
      try {
        const data: any = await this.exceptionService.getPOLines().toPromise();
        response = { podata:data.Po_line_details,sub_total: this.invoice_subTotal };
      } catch (error) {
        console.error('Error fetching PO lines:', error);
        return;
      }
    } 
    this.SpinnerService.show();
    const matdrf:MatDialogRef<PopupComponent> = this.mat_dlg.open(PopupComponent, {
      width: w,
      height: h,
      hasBackdrop: false,
      data: { type: str, resp: response, rejectTxt: this.rejectionComments }
    });
    this.SpinnerService.hide();
    if(str == 'Amend') {
      matdrf.afterClosed().subscribe((resp:any)=>{
        this.rejectionComments = resp;
        if(resp){
          this.Reject();
        }
      })
    }
    
  }
  
  // getPO_lines(str) {
  //   this.exceptionService.getPOLines().subscribe((data: any) => {
  //     this.poLineData = data.Po_line_details;
  //     this.SpinnerService.hide();
  //   }, err => {
  //     this.AlertService.errorObject.detail = "Server error";
  //     this.messageService.add(this.AlertService.errorObject);
  //     this.SpinnerService.hide();
  //   })
  // }

  getGRNnumbers(po_num) {
    this.SharedService.checkGRN_PO_duplicates(po_num).subscribe((data: any) => {
      // data?.result?.forEach((val)=>{
      //   this.grnList.push({docheaderID : val})
      // })
      this.grnList = data?.result;
    });
    this.filterGRNlist = this.grnList;
  }
  ChangeGRNData() {
    if (this.selectedGRNList.length > 0) {
      this.progressDailogBool = false;
      // let arr = [];
      // this.selectedGRNList.forEach(el => {
      //   arr.push(el.docheaderID)
      // })
      this.SharedService.updateGRNnumber(JSON.stringify(this.selectedGRNList)).subscribe(data => {
        this.AlertService.addObject.detail = "GRN Data Updated. Kindly send the invoice to the batch";
        this.messageService.add(this.AlertService.addObject);
      }, err => {
        this.AlertService.errorObject.detail = "Server error";
        this.messageService.add(this.AlertService.errorObject);
      })
    }
  }

  // getGRNtabData() {
  //   this.SharedService.getGRNTabData().subscribe((data: any) => {
  //     this.GRNTabData = data?.result;
  //     this.grnTabDatalength = Object.keys(this.GRNTabData).length;
  //   })
  // }

  setOpened(itemIndex) {
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }

  opengrnDailog() {
    this.GRNDialogBool = true;
    this.progressDailogBool = true;
    this.headerpop = 'Select GRN';
    this.p_width = '70vw';
  }

  getPODocId(po_num) {
    this.SharedService.get_poDoc_id(po_num).subscribe((data: any) => {
      this.poDocId = data.result;
      this.SharedService.po_doc_id = data.result;
    })
  }
  getPO_numbers(idVen){
    this.SpinnerService.show();
    this.SharedService.getPo_numbers(idVen).subscribe((data:any)=>{
      this.poNumbersList = data.result;
      this.filteredPO = data.result;
      this.SpinnerService.hide();
    })
  }
  poDailog(data){
    this.progressDailogBool = true;
    this.headerpop = "Confirm PO number";
    this.p_width = '70vw';
    this.getPO_numbers(this.vendorId);
    this.searchPOArr = data
    this.getDate();
  }
  confirmPO(){
    let updateValue = {
      documentDataID: this.searchPOArr.idDocumentData,
      OldValue: this.searchPOArr.Value || '',
      NewValue: this.SharedService.po_num,
    };
    this.updateInvoiceData.push(updateValue);
    this.saveChanges();
    this.progressDailogBool = false;
    setTimeout(() => {
      this.getInvoiceFulldata('');
    }, 1000);
  }
  getDate() {
    this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let today = new Date();
    let month = today.getMonth();
    this.selectedMonth = this.months[month];
    let year = today.getFullYear();
    this.lastYear = year - 2;
    this.displayYear = `${this.lastYear}:${year}`;
    let prevYear = year - 2;

    this.minDate = new Date();
    this.minDate.setMonth(month);
    this.minDate.setFullYear(prevYear);

    this.maxDate = new Date();
    this.maxDate.setMonth(month);
    this.maxDate.setFullYear(year);
  }

  applyDatefilter(date){
    this.PO_GRN_Number_line = [];
    delete this.activePOId;
    const month = this.datePipe.transform(date, 'yyyy-MM');
    // const frmDate = `${year}-01`;
    // const toDate = this.datePipe.transform(date, 'yyyy-MM');
    
    this.poNumbersList = this.filteredPO;
    this.poNumbersList = this.poNumbersList.filter((element) => {
      
      const dateF = this.datePipe.transform(element.DocumentData.Value, 'yyyy-MM')
      return dateF == month;
    });
  }
  filterPOnumber(event){
    let filtered: any[] = [];
    let query = event.query;
      if (this.poNumbersList?.length > 0) {
        for (let i = 0; i < this.poNumbersList?.length; i++) {
          let PO: any = this.poNumbersList[i];
          if (PO.PODocumentID.toLowerCase().includes(query.toLowerCase())) {
            filtered.push(PO);
          }
          this.filteredPO = filtered;
        }
      }
  }
  POsearch(val){
      if(this.headerpop == 'Confirm PO number'){
        this.poNumbersList = this.filteredPO;
        if (this.poNumbersList?.length > 0) {
          this.poNumbersList = this.poNumbersList.filter(el=>{
            return el.Document.PODocumentID.toLowerCase().includes(val.toLowerCase())
          });
        }
      } else {
        this.grnList = this.filterGRNlist;
        if (this.grnList?.length > 0) {
          this.grnList = this.grnList.filter(el=>{
            return el.GRNNumber.toLowerCase().includes(val.toLowerCase())
          });
        }
      }
  }
  selectedPO(id,event){
    this.updateInvoiceData = [];
    let po_num = document.getElementById(id).innerHTML;
    this.poDate = this.poNumbersList.filter((val)=>{
      return val.Document.PODocumentID == po_num
    });
    this.activePOId = po_num;
    this.SharedService.po_doc_id = po_num;
    this.SharedService.po_num = po_num;
    this.readPOLines(po_num)
  }

  readPOLines(po_num) {
    this.SpinnerService.show();
    this.SharedService.getPO_Lines(po_num).subscribe((data: any) => {
      this.PO_GRN_Number_line = data.result;
      this.SpinnerService.hide();
    }, err => {
      this.AlertService.errorObject.detail = "Server error";
      this.messageService.add(this.AlertService.errorObject);
      this.SpinnerService.hide();
    })
  }
  selectedGRN(event,grn_num){
    this.updateInvoiceData = [];
    let bool:boolean = event.target.checked;
    this.readGRNLines(grn_num,bool);
  }

  readGRNLines(grn_num,bool){
    this.SpinnerService.show();
    this.SharedService.getGRN_Lines(grn_num).subscribe((data: any) => {
      this.PO_GRN_Number_line = data.result;
      this.SpinnerService.hide();
      if(bool){
        let data = [...this.PO_GRN_Number_line]
        this.selectedGRNList.push(grn_num);
        this.selectedGRNLines.push({ grnNumber:grn_num,linesData:data });
      } else {
        if(this.selectedGRNList.length>0) {
          this.selectedGRNList = this.selectedGRNList.filter(grn=> grn != grn_num);
          this.selectedGRNLines = this.selectedGRNLines.filter(grn=> grn.grnNumber != grn_num);
        }
      }
      let total_arr = []
      this.selectedGRNLines.forEach(el=>{
        total_arr.push(el.linesData)
      })
      this.selected_GRN_total = total_arr.reduce((acc, arr) => {
        return acc + arr.reduce((subAcc, obj) => subAcc + parseFloat(obj.AmountExcTax), 0);
      }, 0);

    }, err => {
      this.AlertService.errorObject.detail = "Server error";
      this.messageService.add(this.AlertService.errorObject);
      this.SpinnerService.hide();
    })
  }
  // readPOLines() {
  //   this.exceptionService.getPOLines().subscribe((data: any) => {
  //     this.poLineData = data.Po_line_details;
  //     if (Object?.keys(this.poLineData[0])?.length > 0) {
  //       this.POlineBool = true;
  //     } else {
  //       this.POlineBool = false;
  //     }
  //     this.SpinnerService.hide();
  //   }, err => {
  //     this.AlertService.errorObject.detail = "Server error";
  //     this.messageService.add(this.AlertService.errorObject);
  //     this.SpinnerService.hide();
  //   })
  // }
  // refreshPO() {
  //   this.SpinnerService.show();
  //   this.SharedService.updatePO(this.poDocId).subscribe((data: any) => {
  //     this.readPOLines();
  //     this.SpinnerService.hide();
  //     this.AlertService.addObject.detail = 'PO data updated.';
  //     this.messageService.add(this.AlertService.addObject);
  //   }, err => {
  //     this.SpinnerService.hide();
  //     this.AlertService.errorObject.detail = 'Server error';
  //     this.messageService.add(this.AlertService.errorObject);
  //   })
  // }
  Approve_grn() {
    this.SpinnerService.show();
    this.exceptionService.approve_grn().subscribe((data:any)=>{
      this.SpinnerService.hide();
      if(data?.status?.toLowerCase() == 'posted'){
        this.AlertService.addObject.detail = data.message;
        this.messageService.add(this.AlertService.addObject);
        setTimeout(() => {
          this._location.back();
        }, 1000);
      } else {
        this.progressDailogBool = true;
        this.headerpop = 'GRN Approval Status';
        this.p_width = '350px';
        this.APIResponse = data.message;
        // this.AlertService.errorObject.detail = data.message;
        // this.messageService.add(this.AlertService.errorObject);
      }
    },error=>{
      this.SpinnerService.hide();
      if (error.status == 403) {
        this.progressDailogBool = true;
        this.headerpop = 'GRN Approval Status';
        this.p_width = '350px';
        this.APIResponse = error.error.Error;
        this.AlertService.errorObject.detail = error.error.Error;
      } else {
        this.AlertService.errorObject.detail = 'Server error';
      }
      this.messageService.add(this.AlertService.errorObject);
    })
  }
  closeDialog(){
    this.progressDailogBool = false;
    this.PO_GRN_Number_line = [];
    this.selectedGRNLines = [];
  }

  onSelectFileApprove(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.uploadFileList.push(event.target.files[i]);
    }
    this.uploadSupport();
  }
  onSelectFile(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.uploadFileList.push(event.target.files[i]);

    }
  }
  removeUploadQueue(index) {
    this.uploadFileList.splice(index, 1);
  }

  uploadSupport() {
    this.progress = 1;
    const formData: any = new FormData();
    for (const file of this.uploadFileList) {
      formData.append('files', file, file.name);
    }
    this.SpinnerService.show()
    this.SharedService.uploadSupportDoc(formData)
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.progress = null;
            this.AlertService.addObject.detail = "Supporting Documents uploaded Successfully";
            this.messageService.add(this.AlertService.addObject);
            this.uploadFileList = [];
            event.body?.result?.forEach(ele => {
              this.support_doc_list.push(ele);
            })
            //  setTimeout(() => {
            //  this.getInvoiceFulldata();
            this.SpinnerService.hide()
          }
        }),
        catchError((err: any) => {
          this.progress = null;
          this.AlertService.errorObject.detail = "Server error";
          this.messageService.add(this.AlertService.errorObject);
          this.SpinnerService.hide()
          return throwError(err.message);
        })
      )
      .toPromise();
  }

  downloadDoc(doc_name, type) {
    let encodeString = encodeURIComponent(doc_name);
    this.SharedService.downloadSupportDoc(encodeString).subscribe(
      (response: any) => {
        let blob: any = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        if (type == 'view') {
          // const dailogRef: MatDialogRef<SupportpdfViewerComponent> = this.mat_dlg.open(SupportpdfViewerComponent, {
          //   width: '90vw',
          //   height: '95svh',
          //   hasBackdrop: true,
          //   data: { file: url }
          // })
        } else {
          fileSaver.saveAs(blob, doc_name);
          this.AlertService.addObject.detail = "Document downloaded successfully.";
          this.messageService.add(this.AlertService.addObject);
        }

      },
      (err) => {
        this.AlertService.errorObject.detail = "Server error";
        this.messageService.add(this.AlertService.errorObject);
      }
    );
  }
  ngOnDestroy() {
    // if( this.grnCreateBoolean == false){
    let sessionData = {
      session_status: false,
      "client_address": JSON.parse(localStorage.getItem('userIp'))
    };
    this.exceptionService
      .updateDocumentLockInfo(sessionData)
      .subscribe((data: any) => { });
    // clearInterval(this.timer);
    clearTimeout(this.callSession);
    // }
    // this.timer = null;
    this.AlertService.addObject.severity = 'success';
    this.tagService.financeApprovePermission = false;
    this.tagService.approveBtnBoolean = false;
    this.tagService.submitBtnBoolean = false;
    this.dataService.grnWithPOBoolean = false;
    this.dataService.poLineData = [];
    delete this.SharedService.fileSrc;
    this.mat_dlg.closeAll();
  }
}
