<h6  class="headh6">{{api_route == 'readGRNReadyInvoiceList' ?'GRN Creation':'GRN Approvals'}}</h6>

<div class="container-fluid">
  <div>
    <div >
      <ul class="nav nav-tabs">
        <li class="nav-item mr-1">
          <a
            class="nav-link"
            [class.active-tab]="viewType == 'normal'"
            (click)="chooseEditedpageTab('normal')"
            >{{api_route == 'readGRNReadyInvoiceList' ?'Create GRN with Invoice':'Approval pending'}}({{ dataLength }})</a
          >
        </li>
        <li class="nav-item ml-1" *ngIf="api_route == 'readGRNReadyInvoiceList'">
          <a
            class="nav-link"
            [class.active-tab]="viewType == 'editApproveGRN'"
            (click)="chooseEditedpageTab('editApproveGRN')"
            >GRN Creation with PO</a
          >
        </li>
      </ul>

      <div class="positionCreateUpload">
        <button *ngIf="viewType == 'normal'" class="btnUpload mr-2" (click)="exportExcel()">
          <i class="fa fa-download" aria-hidden="true"></i>
        </button>
        <!-- <button class=" btnUpload mr-2" (click)="toCreateNew()"><span class="f-16">+</span> Create</button> -->
      </div>
      <!-- <div class="filter_input">
                <p-calendar [(ngModel)]="rangeDates" selectionMode="range" placeholder="Select dates to Filter"
                    [readonlyInput]="true" [showIcon]="true" inputId="range"></p-calendar>
                <button class="btnUpload filter_btn_m "> Filter</button>
            </div> -->
      <!-- search by Requestor -->
      <div class="filter_input mr-3" *ngIf="viewType == 'normal'">
        <!-- <label class="f-12 png_label"> Search by PO Requestor </label> <br> -->
        <p-autoComplete
        (onSelect)="onSelectRequestor($event)"
        placeholder="Search by PO Requestor"
        [suggestions]="filteredRequestorList"
        (completeMethod)="filterByRequestor($event)"
        [dropdown]="true"
      >
      </p-autoComplete>
      </div>
    </div>

    <div class="tableDataDiv bg-design-all pt-1" [ngSwitch]="viewType">
      <!-- All Tab -->
      <div *ngSwitchCase="'normal'">
        <div class="tableDiv">
          <div>
            <app-exception-table
              [invoiceColumns]="ColumnsForGRN"
              [columnsToDisplay]="columnsToDisplay"
              [showPaginatorAllInvoice]="showPaginatorAllInvoice"
              [ColumnLength]="GRNTableColumnLength"
              (searchInvoiceData)="searchInvoiceDataV($event)"
              [columnsData]="columnsData"
            >
            </app-exception-table>
          </div>
          
        </div>
      </div>

      <!-- Inprogress Tab -->
      <div *ngSwitchCase="'editApproveGRN'">
        <div class="tableDiv">
          <!-- <div>
            <app-exception-table
              [invoiceColumns]="ColumnsForGRNApproval"
              [columnsToDisplay]="columnsToDisplayGRNApproval"
              (searchInvoiceData)="searchInvoiceDataV($event)"
              [ColumnLength]="approvalPageColumnLength"
              [showPaginatorAllInvoice]="showPaginatorApproval"
              [columnsData]="columnsDataAdmin"
            >
            </app-exception-table>
          </div> -->
          <form
          #PO_GRNForm="ngForm"
          (ngSubmit)="routeToGRN(PO_GRNForm.value)"
          class="d-flex flex-wrap ml-2"
          style="gap: 5px"
        >
        <!-- select PO Number -->
        <div>
          <label class="f-13 mb-0">Enter PO Number</label><br />
          <p-autoComplete
            placeholder="Ex: XXX-PO-XX and press Enter"
            (onSelect)="selectedPO($event)"
            [group]="false"
            [suggestions]="filteredPO"
            (completeMethod)="filterPOnumber($event)"
            (onKeyUp)="getPODetails($event.target.value,$event)"
            (onClick)="getPODetails($event.target.value,$event)"
            field="PODocumentID"
            [dropdown]="true"
            name="PONumber"
            [(ngModel)]="PONumber"
            required
          >
          </p-autoComplete>
          <!-- <input type="text" class="form-control f-12" (keyup)="getPODetails($event.target.value,$event)" placeholder="Ex: RTC-PO-123456 and press Enter" name="PONumber" ngModel required> -->
          <small class="f-12">Note: Please press "Enter"</small>
        </div>

          <div>
            <label class="f-13 mb-0">Entity name</label><br />
            <input type="text" class="form-control f-12" [(ngModel)]="EntityName" name="EntityName" required readonly>
            <!-- <p-autoComplete
              placeholder="Select Entity"
              (onSelect)="selectEntity($event)"
              [group]="false"
              [suggestions]="filteredEnt"
              (completeMethod)="filterEntity($event)"
              field="EntityName"
              [dropdown]="true"
              [completeOnFocus]="true"
              [autofocus]="true"
              name="EntityName"
              [(ngModel)]="entityName"
              required
            >
            </p-autoComplete> -->
          </div>
  
          <!--Vendor Selection-->
          <div>
            <label class="f-13 mb-0">VendorName</label><br />
            <input type="text" class="form-control f-12" [(ngModel)]="vendorName" name="vendor" required readonly>
            <!-- <p-autoComplete
              placeholder="Select vendor"
              (onSelect)="selectedVendor($event)"
              [group]="false"
              [suggestions]="filteredVendors"
              (completeMethod)="filterVendor($event)"
              field="VendorName"
              [dropdown]="true"
              [completeOnFocus]="true"
              [autofocus]="true"
              name="vendor"
              ngModel
              required
            >
            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center">
                <span class="f-12">{{ group.VendorName }}</span>
              </div>
            </ng-template>
            </p-autoComplete> -->
          </div>
  
          
  
           <!-- select GRN Line -->
           <div style="display: none;">
            <label class="f-13 mb-0">Select PO Line</label><br />
            <p-multiSelect
              [options]="poLineData"
              placeholder="line-description"
              filter="false"
              inputStyleClass="form-control"
              optionLabel="Name"
              [maxSelectedLabels]="3"
              [selectedItemsLabel]="'{0} items selected'"
              name="PO_GRN_Number_line"
              ngModel
              required
              [(ngModel)]="PO_GRN_Number_line"
            >
            </p-multiSelect>
          </div>
  
          
          <div>
            <div style="visibility: hidden">visible</div>
            <button
              class="btnUpload b-0 mt-0 f-13"
              style="background: royalblue !important"
              [disabled]="PO_GRNForm.invalid"
              [ngClass]="PO_GRNForm.invalid ? 'disable' : ''"
            >
             Proceed to GRN creation page
            </button>
          </div>
        </form>
        <!-- <div *ngIf="columnsDataPO.length > 0" class="mt-2">
          <app-exception-table
            [invoiceColumns]="ColumnsForGRN"
            [columnsToDisplay]="columnsToDisplay"
            [showPaginatorAllInvoice]="showPaginator"
            [ColumnLength]="GRNTableColumnLength"
            (searchInvoiceData)="searchInvoiceDataV($event)"
            [columnsData]="columnsDataPO"
          >
          </app-exception-table>
        </div> -->
        </div>
      </div>

      <ngx-spinner
            bdColor="rgba(251, 251, 251, 0.8)"
            size="medium"
            color="#070900"
            [fullScreen]="false"
            type="ball-spin-clockwise"
          >
            <p style="color: rgb(0, 0, 0)"></p>
          </ngx-spinner>
    </div>
  </div>
</div>
<p-toast></p-toast>
