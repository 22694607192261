<div *ngIf="!editable && !grnCreateBoolean" class="pos_rel">
  <span class="goBackIn ml-3" (click)="backToInvoice()">
    <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
  </span>
  <h6 class="headh6 pl-80">View {{ Itype }}</h6>
</div>
<div *ngIf="editable || grnCreateBoolean" class="pos_rel">
  <span class="goBackIn ml-3" (click)="backToInvoice()">
    <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
  </span>
  <h6 class="headh6 pl-80">{{ headerName }}</h6>
</div>
<div class="row invoice-body">
  <div
    class="viewPdf"
    style="right: 340px"
    *ngIf="
      (editPermissionBoolean ||
        changeApproveBoolean ||
        financeApproveBoolean) &&
      submitBtn_boolean &&
      this.currentTab == 'line' &&
      isDesktop && router.url.includes('comparision-docs')
    "
  >
    <!-- <button
      class="btnUpload btn_extra_bg mr-2"
      (click)="open_dialog_comp('Amend')"
    >
      PO Amend request
    </button> -->

    <button
    *ngIf="portalName == 'customer' && (subStatusId == 75 || subStatusId == 17)"
      class="btnUpload m-t-neg btn_extra_bg ml-2 mr-2"
      [ngClass]="subStatusId == 75 || subStatusId == 17 ? 'bt-hlt' : ''"
      (click)="opengrnDailog()"
    >
      Select GRN
    </button>
    <button
      *ngIf="
        portalName == 'customer' && (subStatusId == 8 ||
        subStatusId == 16 ||
        subStatusId == 17 ||
        subStatusId == 21 ||
        subStatusId == 27)
      "
      class="btnUpload btn_extra_bg"
      (click)="open_dialog_comp('flip line')"
    >
      Flip PO
    </button>
  </div>
  <div
    class="viewPdf"
    style="right: 100px"
    *ngIf="!isPdfAvailable && isDesktop"
  >
    <button class="btnUpload" (click)="DownloadPDF()">
      <i class="fa fa-download" aria-hidden="true"></i>
    </button>
  </div>
  <div class="viewPdf" *ngIf="!isPdfAvailable && isDesktop">
    <button class="btnUpload" (click)="viewPdf()">{{ btnText }}</button>
  </div>
  <!-- <div *ngIf="submitBtn_boolean || approveBtn_boolean && isDesktop" class="position_rule">
    <div class="placement-ruleChange f-12 bg-div-rule">
      Current Selected rule : {{ selectedRule }}
    </div>
    <button
      class="placement-ruleChange btnUpload bg-grn-btn"
      (click)="open_modal()"
    >
      Change
    </button>
  </div> -->
  <!-- <div *ngIf="approveBtn_boolean">
    <div
      class="placement-ruleChange f-12 bg-div-rule"
      style="left: inherit; right: 145px; position: absolute"
    >
      Current Selected Approval Type : {{ approvalType }}
    </div>
  </div> -->

  <div *ngIf="submitBtn_boolean && isDesktop && !GRN_PO_Bool && router.url.includes('comparision-docs')" class="changePOInput">
    <p-autoComplete
      [(ngModel)]="selectedPONumber"
      (onSelect)="onSelectPO($event)"
      placeholder="Select PO Number"
      [suggestions]="filteredPO"
      (completeMethod)="filterPO($event)"
      field="PODocumentID"
      [dropdown]="true"
    >
      <ng-template let-country pTemplate="item">
        <div class="country-item">
          <div class="f-12">{{ country.PODocumentID }}</div>
        </div>
      </ng-template>
    </p-autoComplete>
  </div>
  <div
    class="col-md-12 p-0 pl-1"
    [ngClass]="showPdf && !isPdfAvailable ? 'col-lg-5' : 'col-lg-12'"
  >
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li
        class="nav-item mr-2"
        pTooltip="Vendor details"
        tooltipPosition="top"
        (click)="changeTab('vendor')"
      >
        <a
          class="nav-link"
          id="vendor-tab"
          data-toggle="tab"
          href="#vendor"
          role="tab"
          aria-controls="vendor"
          aria-selected="true"
          >Vendor</a
        >
      </li>
      <li
        class="nav-item mr-2"
        pTooltip="Header details"
        tooltipPosition="top"
        (click)="changeTab('header')"
      >
        <a
          class="nav-link"
          id="header-tab"
          data-toggle="tab"
          href="#header"
          role="tab"
          aria-controls="header"
          aria-selected="false"
          >Header</a
        >
      </li>
      <li
        class="nav-item"
        pTooltip="Line details"
        tooltipPosition="top"
        (click)="changeTab('line')"
      >
        <a
          class="nav-link active"
          id="line-tab"
          data-toggle="tab"
          href="#line"
          role="tab"
          aria-controls="line"
          aria-selected="false"
          [ngClass]="grnCreateBoolean ? 'active' : ''"
          >Line details</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="PO Lines details"
        tooltipPosition="top"
        (click)="changeTab('poline')"
        *ngIf="Itype == 'Invoice'"
      >
        <a
          class="nav-link"
          id="POline-tab"
          data-toggle="tab"
          href="#POline"
          role="tab"
          aria-controls="POline"
          aria-selected="false"
          >PO Lines</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="GRN details"
        tooltipPosition="top"
        *ngIf="!grnCreateBoolean"
        (click)="changeTab('grn')"
      >
        <a
          class="nav-link"
          id="grn-tab"
          data-toggle="tab"
          href="#grn"
          role="tab"
          aria-controls="grn"
          aria-selected="false"
          >GRN Data</a
        >
      </li>

      <!-- <li
      class="nav-item ml-2"
      pTooltip="Supporting documents"
      tooltipPosition="top"
      *ngIf="Itype == 'Invoice' && grnCreateBoolean"
      (click)="changeTab('support')"
    >
      <a
        class="nav-link"
        id="support-tab"
        data-toggle="tab"
        href="#support"
        role="tab"
        aria-controls="support"
        aria-selected="false"
        >Supporting
        Docs</a
      >
    </li> -->
      <!-- <button class="raiseAlert" pTooltip="raise an alert" tooltipPosition="top"><i class="fa fa-exclamation-triangle"
            aria-hidden="true"></i></button> -->
    </ul>
    <div class="tabsDiv bg-design-all">
      <div *ngIf="!isDesktop" class="vi_mb_btn f-12" (click)="doc_view()">
        <i class="fa fa-eye"></i> View Document
      </div>
      <form #form="ngForm" class="tabDiv mt-3" id="myTabContent">
        <div class="tab-content">
          <ng-container *ngTemplateOutlet="vendor"></ng-container>
          <ng-container *ngTemplateOutlet="header"></ng-container>
          <ng-container *ngTemplateOutlet="line"></ng-container>
          <ng-container *ngTemplateOutlet="poline"></ng-container>
          <ng-container *ngTemplateOutlet="grn"></ng-container>
          <ng-container *ngTemplateOutlet="support"></ng-container>
        </div>

        <ng-template #vendor>
          <!-- vendor -->
          <div
            class="tab-pane fade"
            id="vendor"
            role="tabpanel"
            aria-labelledby="vendor-tab"
          >
            <div class="displayFlex">
              <div *ngFor="let data of vendorData | keyvalue; let i = index">
                <div>
                  <label class="label-head" style="margin-bottom: -5px"
                    >{{ data.key }}
                  </label>
                  <input
                    #inputv
                    type="text"
                    [value]="data.value"
                    [title]="data.value"
                    [disabled]="!vendorDetalilsEditBoolean"
                    class="form-control mb-1 inputHeight"
                    (blur)="method()"
                  />
                  <!-- (click)="drawrectangleonHighlight(i)"                 
                  [ngStyle]="{'border': (value.isError === 1 ) ? '2px solid red' : (value.isError === 0 && value.IsUpdated === 0  ) ? '1px solid lightgray': (value.IsUpdated === 1 ) ? '2px solid #37dcc7':'' } "
                (change)="onChangeValue(value.TagLabel,inputv.value,value)"-->
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #header>
          <!-- header -->
          <div
            class="tab-pane fade"
            id="header"
            role="tabpanel"
            aria-labelledby="header-tab"
          >
          <div class="displayFlex mt-2" *ngIf="Itype == 'Invoice' && !router.url.includes('Create_GRN_inv_list')">
            <div *ngFor="let x of inputData | keyvalue">
              <!-- {{x.key | json}} : {{ x.value | json}} -->
              <!-- [ngStyle]="{ 'width':value?.TagLabel?.includes('Vendor') ? 'calc(100svw - 74svw)':''}" -->
              <div style="position: relative">
                <label class="label-head" style="margin-bottom: -5px"
                  >{{ x.key }}
                  <span
                    *ngIf="x.value.isError >= 1"
                    [pTooltip]="x.value.ErrorDesc"
                    tooltipPosition="top"
                    ><i
                      class="fa fa-info-circle info_icon"
                      aria-hidden="true"
                    ></i
                  ></span>
                  <span
                    *ngIf="x.key == 'PurchaseOrder' && editable"
                  >
                    <i
                      class="fa fa-search info_icon pointer po_s_icon"
                      aria-hidden="true"
                      (click)="poDailog(x.value)"
                    ></i>
                  </span>
                </label>
                <input
                  #inputv
                  type="text"
                  [value]="x.value.Value"
                  [title]="x.value.Value"
                  [disabled]="!editable || fin_boolean"
                  [ngStyle]="{
                    border:
                    x.value.isError >= 1
                        ? '2px solid red'
                        : x.value.isError === 0 && x.value.IsUpdated === 0
                        ? '1px solid lightgray'
                        : x.value.IsUpdated === 1
                        ? '2px solid #37dcc7'
                        : ''
                  }"
                  (change)="
                    onChangeValue(x.key, inputv.value, x.value)
                  "
                  (blur)="saveChanges()"
                  class="form-control mb-1 inputHeight"
                />
                <span *ngIf="x.value?.DocumentUpdates?.OldValue">
                  <span class="old_value"
                    >Prev :
                    <span
                      style="color: #f38a6b"
                      [title]="x.value?.DocumentUpdates?.OldValue"
                      >"{{ x.value?.DocumentUpdates?.OldValue }}"</span
                    >
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="displayFlex" *ngIf="router.url.includes('Create_GRN_inv_list')">
            <div *ngFor="let value of inputData">
              <div style="position: relative">
                <label class="label-head" style="margin-bottom: -5px"
                  >{{ value.TagLabel }}
                  <span
                    *ngIf="value.isError >= 1"
                    [pTooltip]="value.ErrorDesc"
                    tooltipPosition="top"
                    ><i
                      class="fa fa-info-circle info_icon"
                      aria-hidden="true"
                    ></i
                  ></span>
                </label>
                <input
                  #inputv
                  type="text"
                  [value]="value.Value"
                  [title]="value.Value"
                  disabled
                  [ngStyle]="{
                    border:
                      value.isError >= 1
                        ? '2px solid red'
                        : value.isError === 0 && value.IsUpdated === 0
                        ? '1px solid lightgray'
                        : value.IsUpdated === 1
                        ? '2px solid #37dcc7'
                        : ''
                  }"
                  class="form-control mb-1 inputHeight"
                />
                <span
                  *ngIf="
                    value.DocumentUpdates && value?.DocumentUpdates?.OldValue
                  "
                >
                  <span class="old_value"
                    >Prev:
                    <span
                      style="color: #f38a6b"
                      [title]="value?.DocumentUpdates?.OldValue"
                      >"{{ value.DocumentUpdates.OldValue }}"</span
                    ></span
                  >
                </span>
              </div>
            </div>
          </div>
          </div>
        </ng-template>

        <ng-template #line>
          <!-- line -->
          <div
            class="tab-pane fade show active"
            [ngClass]="grnCreateBoolean ? 'show active' : ''"
            id="line"
            role="tabpanel"
            aria-labelledby="line-tab"
          >
            <div
              *ngIf="
                !isDesktop &&
                editable &&
                router.url.includes('comparision-docs')
              "
              class="d-flex mb-1"
              style="gap: 5px"
            >
              <button
                *ngIf="ap_boolean && portalName == 'customer' &&(subStatusId == 75 || subStatusId == 17)"
                class="btnUpload m-t-neg btn_extra_bg ml-2 mr-2"
                [ngClass]="
                  subStatusId == 75 || subStatusId == 17 ? 'bt-hlt' : ''
                "
                (click)="opengrnDailog()"
              >
                Select GRN
              </button>
              <button
                *ngIf="
                  portalName == 'customer' &&
                  flipEnabled &&
                  (subStatusId == 8 ||
                    subStatusId == 16 ||
                    subStatusId == 17 ||
                    subStatusId == 21 ||
                    subStatusId == 27)
                "
                class="btnUpload btn_extra_bg m-t-neg ml-2"
                (click)="open_dialog_comp('flip line', '')"
              >
                Flip PO
              </button>
            </div>
            <div *ngIf="!grnCreateBoolean">
              <div
                class="d-flex"
                [ngClass]="
                  isDesktop ? 'justify-content-end' : 'justify-content-start'
                "
              >
                <div class="d-flex mr-3">
                  <div class="represent_color invoice_color"></div>
                  <div class="f-12">Invoice</div>
                </div>

                <div class="d-flex mr-3">
                  <div class="represent_color po_color"></div>
                  <div class="f-12">PO</div>
                </div>

                <!-- <div class="d-flex">
                <div class="represent_color grn_color"></div>
                <div class="f-12">GRN</div>
              </div> -->
              </div>

              <table class="w-100 mt-2">
                <tr>
                  <td class="count_line">
                    <span class="f-12 p-2" style="margin-top: -6px">S.No</span>
                  </td>
                  <td *ngFor="let tag of tagNameArr | keyvalue" [ngStyle]="{
                    width:
                      tag.key == 'Description' ? '100%' : '',
                    minWidth:
                      tag.key == 'Description' ? '100%' : '100px',
                    textAlign:
                      tag.key == 'Description' ? 'left' : 'center'
                  }">
                    <span class="f-13" *ngIf="tag.key != '_NetTotal'">{{ tag.key == '_U_Act' ? "Act" : tag.key}}</span>
                    <span class="f-13" *ngIf="tag.key == '_NetTotal'">Net total</span>
                  </td>
                </tr>
                <tr *ngFor="let tagName of lineDisplayData; let i_i = index">
                  <td class="count_line">
                    <div
                      class="f-12 mb-1 count-h"
                    >
                      {{ i_i + 1 }}
                      <!-- <div class="f-12 td_padding invoice_color doc_height">
                        {{ i + 1 }}
                      </div>
                      <div class="f-12 td_padding po_color doc_height"></div> -->
                    </div>
                  </td>

                  <td
                    class="f-13"
                    *ngFor="let data of tagName | keyvalue; let i = index"
                    [ngStyle]="{
                      width:
                        data.key == 'Description' ? '100%' : '',
                      minWidth:
                        data.key == 'Description' ? '100%' : '90px',
                      textAlign:
                        data.key == 'Description' ? 'left' : 'center'
                    }"
                  >
                    <!-- {{data?.value.Value | json}} -->
                    <!-- <ng-container
                      *ngFor="let data of tagName | keyvalue; let l_i = index"
                    > -->
                    
                      <!-- <ng-container
                        *ngFor="let data2 of data.linedetails"
                        class="mb-1"
                      > -->

                        <div
                          class="f-12 td_padding invoice_color doc_height"
                          data-text="IN"
                          [ngClass]="
                            data.key == 'Description'
                              ? 'textType in_bg_clr'
                              : ''
                          "
                          [ngStyle]="{
                            justifyContent:
                              data.key == 'Description' ? '' : 'center'
                          }"
                        >
                          <div *ngIf="data.key != '_U_Act'" class="d-flex" 
                          [ngClass]="
                          data.key == 'Description'
                            ? 'w-100'
                            : ''
                        ">
                            <input
                              type="text"
                              #lineInput
                              [title]="data?.value?.Value"
                              [value]="data?.value?.Value"
                              class="f-12 input_disabled w-fill"
                              style="text-transform: lowercase"
                              [disabled]="!editable || fin_boolean"
                              (change)="
                                onChangeLineValue(
                                  data?.key,
                                  lineInput.value,
                                  data?.value
                                )
                              "
                              (blur)="saveChanges()"
                              [ngClass]="!editable ? 'input_disabled' : ''"
                              [ngStyle]="{
                                width:
                                  data.key == 'Description'
                                    ? '-webkit-fill-available'
                                    : '120px',
                                textAlign:
                                  data.key == 'Description'
                                    ? 'left'
                                    : 'center',
                                borderBottom:
                                  data?.value?.isError >= 1
                                    ? ' 2px solid red'
                                    : ''
                              }"
                            />
                            <span
                              *ngIf="data?.value?.DocumentUpdates"
                              class="prev_value"
                              [title]="data?.value?.DocumentUpdates?.OldValue"
                              >prev:
                              {{
                                data?.value?.DocumentUpdates?.OldValue
                              }}</span
                            >
                            <span
                              *ngIf="data?.value?.isError >= 1"
                              [pTooltip]="data?.value?.ErrorDesc"
                              tooltipPosition="top"
                              ><i
                              style="color: red;"
                                class="fa fa-info-circle info_icon"
                                aria-hidden="true"
                              ></i
                            ></span>
                          </div>
                          <div *ngIf="data.key == '_U_Act'">
                            <button
                              class="trash_btn"
                              (click)=" delete_confirmation(data?.value?.itemCode)"
                            >
                              <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                        <div
                          class="f-12 td_padding po_color doc_height"
                          data-text="PO"
                          [ngClass]="
                            data.key == 'Description'
                              ? 'textType po_bg_clr'
                              : ''
                          "
                          [ngStyle]="{
                            justifyContent:
                              data.key == 'Description' ? '' : 'center'
                          }"
                        >
                          <input
                            *ngIf="
                              data.key != '_NetTotal' && data.key != '_U_Act' &&
                              (data.key != 'Description' ||
                                !submitBtn_boolean)
                            "
                            type="text"
                            [title]="data?.value?.po_line?.Value"
                            [value]="data?.value?.po_line?.Value"
                            class="f-12 input_disabled w-fill"
                            style="text-transform: lowercase"
                            disabled
                            [ngClass]="!editable ? 'input_disabled' : ''"
                            [ngStyle]="{
                              width:
                                data.key == 'Description'
                                  ? '-webkit-fill-available'
                                  : '150px',
                              textAlign:
                                data.key == 'Description'
                                  ? 'left'
                                  : 'center'
                            }"
                          />
                          <input
                            *ngIf="data.key == '_NetTotal' && data.key != '_U_Act'" 
                            type="text"
                            [title]="data?.value?.po_line?.Value"
                            [value]="
                              (
                                lineDisplayData[i_i]?.UnitPrice?.po_line?.Value *
                                lineDisplayData[i_i]?.Quantity?.po_line?.Value
                              ).toFixed(2)
                            "
                            class="f-12 input_disabled w-fill"
                            style="text-transform: lowercase"
                            disabled
                            [ngClass]="!editable ? 'input_disabled' : ''"
                            [ngStyle]="{
                              width:
                                data.key == 'Description'
                                  ? '-webkit-fill-available'
                                  : '150px',
                              textAlign:
                                data.key == 'Description'
                                  ? 'left'
                                  : 'center'
                            }"
                          />
                          <div
                            class="mapping"
                            *ngIf="
                              data.key == 'Description' && data.key != '_U_Act' &&
                              submitBtn_boolean
                            "
                          >
                            <p-autoComplete
                              *ngIf="data.key == 'Description'"
                              (onSelect)="
                                lineMapping(
                                  data?.value?.itemCode,
                                  $event.itemCode,
                                  data?.value?.invoice_itemcode
                                )
                              "
                              [(ngModel)]="data.value.po_line"
                              [suggestions]="filteredPOLine"
                              (completeMethod)="filterPOLine($event)"
                              field="Value"
                              [id]="data?.value?.po_line?.idDocumentLineItems"
                              [name]="data?.value?.po_line?.Value"
                              styleClass="f-11 input_disabled sel_drp"
                              [dropdown]="true"
                            >
                              <ng-template let-line pTemplate="item">
                                <div class="line-item">
                                  <div class="f-11">{{ line.Value }}</div>
                                </div>
                              </ng-template>
                            </p-autoComplete>
                          </div>
                        </div>
                      <!-- </ng-container> -->
                    <!-- </ng-container> -->
                  </td>

                  <!-- <td *ngIf="editable">
                    <span class="f-13 p-2 mapping_line hide">Act</span>
                    <div style="margin-top: -5px">
                      <div *ngFor="let data of lineCount">
                        <div *ngFor="let data2 of data.linedetails" class="mb1">
                          <div class="f-12 td_padding invoice_color doc_height">
                            <button
                              class="trash_btn"
                              (click)="
                                delete_confirmation(
                                  data2?.Description?.invoice_itemcode
                                )
                              "
                            >
                              <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                          </div>
                          <div class="f-12 td_padding po_color doc_height">
                            <div
                              class="f-12 d-flex"
                              *ngIf="submitBtn_boolean"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td> -->
                </tr>
              </table>
            </div>

            <!-- Create GRN screen Line details -->
            <div *ngIf="grnCreateBoolean">
              <!--GRN Creation With Invoice-->
              <div class="box" *ngIf="GRN_PO_Bool">
                <label for="inv" class="f-14 mb-0" style="color: #358cd0;">Invoice number</label><br>
                <input type="text" class="inputHeight form-control f-14" id="inv" name="invNumber" placeholder="Ex:inv1234" [(ngModel)]="invoiceNumber">
              </div>
              <table class="invert" style="width: 100%">
                <tr class="invert_tr">
                  <td
                    class="lineName invert_th_td text-center"
                    style="min-width: 50px"
                  >
                    S.No
                  </td>
                  <td
                    class="count_line"
                    *ngFor="let item2 of grnLineCount; let ind = index"
                  >
                    <div class="s-no">
                      {{ ind + 1 }}
                    </div>
                  </td>
                </tr>
                <ng-container *ngFor="let item of lineDisplayData; let i = index">
                  <tr
                  class="invert_tr" *ngIf="!item.TagName.includes('UnitPrice') && item.TagName != 'Inv - AmountExcTax'">
                  <td
                    class="lineName invert_th_td"
                    *ngIf="
                      !item.TagName.includes('UnitPrice') && item.TagName != 'Inv - AmountExcTax'
                    "
                    [ngClass]="item.TagName === 'Actions' ? 'm-l-neg' : ''"
                  >
                    <span *ngIf="item.TagName != 'GRN - AmountExcTax'">{{ item.TagName }}</span>
                    <span *ngIf="item.TagName == 'GRN - AmountExcTax'">AmountExcTax</span>
                  </td>
                  <td
                    class="linevalue invert_th_td"
                    *ngFor="let line1 of item.linedata"
                  >
                    <div
                      *ngIf="
                        item.TagName == 'PO quantity' ||
                        item.TagName == 'Inv - Quantity' ||
                        item.TagName == 'Description' ||
                        item.TagName == 'GRN - AmountExcTax' ||
                        item.TagName == 'PO Qty' ||
                        item.TagName == 'AmountExcTax' ||
                        item.TagName == 'PO LineNumber'
                      "
                    >
                      <span class="d-flex">
                        <input
                          type="text"
                          [ngClass]="
                            item.TagName == 'Description' ? 'w-des' : 'w-sm'
                          "
                          [ngStyle]="{
                            border:
                              line1.is_mapped === 1 ? '2px solid #f2be42' : ''
                          }"
                          [name]="item.TagName"
                          [value]="line1.Value"
                          [title]="line1.Value"
                          disabled
                          class="form-control mb-1 inputHeight"
                        />
                      </span>
                      <!-- <span [ngStyle]="{'visibility':line1.POValue ? 'visible':'hidden'}">
                        <span
                      data-text="PO"
                      class="d-flex"
                      [ngClass]="item.TagName == 'Description' ? 'textType' : ''" 
                      >
                      <input
                          type="text"
                          [ngClass]="
                            item.TagName == 'Description' ? 'w-des' : 'w-sm'
                          "
                          [name]="item.TagName"
                          [value]="line1.POValue"
                          [title]="line1.POValue"
                          [disabled]="(item.TagName != 'GRN - Quantity')"
                          class="form-control mb-1 inputHeight"
                        />
                    </span>
                        
                      </span> -->
                    </div>
                    <div
                      *ngIf="
                        (item.TagName == 'GRN - Quantity')
                      "
                    >
                      <span>
                        <input
                          #inputval
                          type="text"
                          [ngClass]="
                            item.TagName == 'Description' ? 'w-des' : 'w-sm'
                          "
                          style="border: 1px solid #efc384"
                          [name]="line1.idDocumentLineItems"
                          [value]="line1.Value"
                          [title]="line1.Value"
                          class="form-control mb-1 inputHeight"
                          (change)="onChangeGrn(line1, inputval.value)"
                          (blur)="router.url.includes('GRN_approvals')?saveChanges():''"
                          [(ngModel)]="line1.Value"
                        />
                      </span>
                      <!-- <span style="visibility: hidden;">
                        <input
                          type="text"
                          class="form-control mb-1 inputHeight"
                        />
                      </span> -->
                    </div>
                    <div
                      *ngIf=" 
                        !GRN_PO_Bool &&
                        item.TagName == 'Comments'
                      "
                    >
                      <span>
                        <input
                          #inputval
                          type="text"
                          style="width: 150px"
                          [ngClass]="
                            item.TagName == 'Description' ? 'w-des' : 'w-sm'
                          "
                          [ngModelOptions]="{ standalone: true }"
                          [value]="line1.ErrorDesc"
                          [title]="line1.ErrorDesc"
                          class="form-control mb-1 inputHeight"
                          [(ngModel)]="line1.ErrorDesc"
                        />
                      </span>
                      <!-- <span style="visibility: hidden;">
                        <input
                          type="text"
                          class="form-control mb-1 inputHeight"
                        />
                        (click)="open_dialog('delete', line.value)"
                      </span> -->
                    </div>
                    <div *ngIf="item.TagName === 'Actions' && GRN_PO_Bool">
                      <span class="linevalue invert_thtd">
                        <button
                          style="border-color: transparent"
                          (click)="deleteGrnLine(line1.idDocumentLineItems)"
                        >
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                      </span>
                    </div>
                  </td>
                  <!-- <td class="linevalue invert_th_td total">
                    <div *ngIf="item.TagName == 'S.No'"><span style="visibility: hidden;"><input type="text" disabled value="Total"></span></div>
                    <div *ngIf="item.TagName == 'Description'"><input type="text" disabled value="Total"></div>
                    <div *ngIf="item.TagName == 'GRN - AmountExcTax'"><input type="text" disabled value="230"></div>
                    <div *ngIf="item.TagName != 'GRN - AmountExcTax' && item.TagName != 'Description'">
                      <span style="visibility: hidden;"><input type="text" disabled [value]="Total"></span>
                    </div>
                  </td> -->
                </tr>
                </ng-container>
                
              </table>

              <table
              class="total"
              *ngIf="router.url.includes('Inv_vs_GRN_details') || router.url.includes('GRN_approvals')"
            >
              <tr>
                <td class="w-st hide">Total</td>
                <td class="w-des f-12">Total</td>
                <td class="w-st hide">Total</td>
                <td class="w-st hide" *ngIf="!GRN_PO_Bool">Total</td>
                <td class="w-st f-12"
                *ngIf="!router.url.includes('GRN_approvals')"
                [ngClass]="GRN_PO_Bool?'':'pl-18'"
                >
                  {{ GRN_line_total.toFixed(2) }}
                </td>
                <td class="w-st f-12 GRNApproval"
                *ngIf="router.url.includes('GRN_approvals')"
                >
                  {{ GRN_line_total.toFixed(2) }}
                </td>
                <td
                *ngIf="!router.url.includes('GRN_approvals')"
                  class="w-st hide"
                  [ngStyle]="{ width: GRN_PO_Bool ? '250px' : '200px' }"
                >
                  Total
                </td>
                <td class="w-st hide">Total</td>
                <!-- <td class="w-st hide" *ngIf="GRN_PO_Bool">Total</td> -->
              </tr>
            </table>
            </div>
          </div>
        </ng-template>

        <!--PO lines-->
        <ng-template #poline>
          <div
            *ngIf="currentTab == 'poline'"
            class="tab-pane fade"
            id="POline"
            role="tabpanel"
            aria-labelledby="POline-tab"
          >
            <div style="margin-top: 35px">
              <po-lines [po_num]="po_num"></po-lines>
            </div>
          </div>
        </ng-template>

        <ng-template #grn>
          <div
            class="tab-pane fade show"
            id="grn"
            role="tabpanel"
            aria-labelledby="grn-tab"
          >
            <mapped-grn></mapped-grn>
          </div>
        </ng-template>

        <!-- Support Doc -->
        <ng-template #support>
          <div
            class="tab-pane fade"
            id="support"
            role="tabpanel"
            aria-labelledby="support-tab"
            style="position: relative"
          >
            <div
              *ngIf="
                support_doc_list?.length > 0 &&
                !approval_selection_boolean
              "
            >
              <table class="table">
                <tr class="f-12 lineName">
                  <th>S.No</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                <tr
                  *ngFor="let file of support_doc_list; let i = index"
                  class="f-11"
                >
                  <td>{{ i + 1 }}</td>
                  <td
                    class="break_word pointer"
                    (click)="downloadDoc(file, 'view')"
                  >
                    {{ file }}
                  </td>
                  <td>
                    <i
                      class="fa fa-download pointer"
                      aria-hidden="true"
                      (click)="downloadDoc(file, 'down')"
                    ></i>
                  </td>
                </tr>
              </table>
              <p class="f-11">
                *Supporting document is not being read by Serina, please check
                and review manually.
              </p>
            </div>
            <div
              *ngIf="
                !(support_doc_list?.length > 0) &&
                !approval_selection_boolean
              "
            >
              <div class="no_doc_div f-14">
                No Supporting Document available, please upload if you have any.
              </div>
            </div>
            <div class="file_table" *ngIf="uploadFileList.length > 0">
              <table class="table">
                <tr class="f-12">
                  <th>S.No</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                <tr
                  *ngFor="let file of uploadFileList; let i = index"
                  class="f-11"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ file.name }}</td>
                  <td>
                    <i
                      class="fa fa-trash-o"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeUploadQueue(i)"
                    ></i>
                  </td>
                </tr>
              </table>
            </div>
            <div *ngIf="!approval_selection_boolean">
              <label
                for="img"
                class="img-browse"
                [ngClass]="uploadFileList.length > 0 ? 'disable_clas' : ''"
              >
                Attach Document
              </label>
              <input
                (change)="onSelectFile($event)"
                #fileInput
                type="file"
                id="img"
                name="img"
                accept=".pdf,.doc, .docx"
                multiple
                [disabled]="uploadFileList.length > 0"
                hidden
              />
              <!-- <input type="file" multiple (change)="uploadSupport($event)"> -->

              <button
                class="img-browse h-35 b-0 ml-2"
                (click)="uploadSupport()"
                [disabled]="!(uploadFileList.length > 0)"
                [ngClass]="!(uploadFileList.length > 0) ? 'disable_clas' : ''"
              >
                Upload
              </button>
            </div>
          </div>
        </ng-template>

        <div
          *ngIf="editable && !grnCreateBoolean"
          class="btnFooter"
          mat-dialog-actions
        >
          <!-- <button mat-button class="btn btnVender mr-4" type="button">Change attachment</button> -->
          <button
            class="btn btnVender bg-btn-cancel mr-3"
            type="button"
            (click)="backToInvoice()"
          >
            Cancel
          </button>
          <button
            *ngIf="submitBtn_boolean"
            class="btn btnVender bg-btn-cancel mr-3"
            (click)="displayrejectDialog = true"
          >
            Reject
          </button>
          <button
            *ngIf="submitBtn_boolean && financeapproveDisplayBoolean"
            class="btn btnVender bg-btn-success mr-3"
            (click)="reviewManualApprove()"
          >
            Review to Manual Approval
          </button>
          <!-- <button
            *ngIf="submitBtn_boolean"
            class="btn btnVender bg-btn-success"
            (click)="send_review_modal()"
          >
            Review to Batch
          </button> -->
          <button
            *ngIf="approveBtn_boolean && financeapproveDisplayBoolean"
            class="btn btnVender bg-btn-success mr-3"
            (click)="approveChangesManual()"
          >
            Send to Manual Approval
          </button>
          <button
            *ngIf="submitBtn_boolean"
            class="btn btnVender bg-btn-success"
            (click)="approveChangesBatch()"
          >
            Send to Batch
          </button>

          <!-- <button *ngIf="financeApproveBoolean && fin_boolean" class="btn btnVender" style="min-width: fit-content;"
          (click)="financeApprove()">Finance
          Approve</button> -->
          <!-- <button *ngIf=" flipBoolean" class="btn btnVender mr-2 " (click)="Create()" >Create</button>
            <button *ngIf="!tabChangeLINEBoolean && flipBoolean"  class="btn btnVender mr-2" (click)="displayAddLabelDialog = true" pTooltip="Add Label" tooltipPosition="top" >Add Label</button>
            <button *ngIf="tabChangeLINEBoolean && flipBoolean" class="btn btnVender " (click)="addLine()" >Add Line</button> -->
        </div>

        <div
          *ngIf="grnCreateBoolean && isGRNDataLoaded"
          class="GRN_btn_position"
        >
          <button
            class="btn btnVender bg-btn-cancel mr-3"
            type="button"
            (click)="backToInvoice()"
          >
            Cancel
          </button>
          <button
            *ngIf="!GRN_PO_Bool"
            class="btn btnVender bg-btn-cancel mr-3"
            type="button"
            (click)="displayrejectDialog = true"
          >
            Reject
          </button>
          <!-- <button
            *ngIf="!GRN_PO_Bool"
            class="btn btnVender bg-btn-success mr-3"
            type="submit"
            (click)="
            confirm_pop(form.value, false, 'GRN data saved successfully')
            "
          >
            Save
          </button> -->
          <button
            class="btn btnVender bg-btn-success mr-3"
            type="submit"
            [disabled]="!enable_create_grn"
            (click)="confirm_pop(form.value, true, 'GRN created successfully')"
          >
          {{ router.url.includes('GRN_approvals')?'Approve' :'Create GRN'}}
          </button>
        </div>
      </form>
      <ngx-spinner
        bdColor="rgba(251, 251, 251, 0.8)"
        size="medium"
        color="#070900"
        [fullScreen]="true"
        type="ball-spin-clockwise"
      >
        <p style="color: rgb(0, 0, 0)"></p>
      </ngx-spinner>
    </div>
  </div>
  <div
    *ngIf="showPdf"
    class="col-lg-7 col-12 maxPage ShowInvoiceTypeSize doc_mobile"
    [ngStyle]="{ display: documentViewBool || isDesktop ? 'block' : 'none' }"
  >
    <div>
      <section *ngIf="isDesktop">
        <div *ngIf="isImgBoolean" class="canvasConatiner_height">
          <div id="parentDiv">
            <canvas #canvas id="canvas1" style="height: 87vh"></canvas>
          </div>
          <span class="zoom zoom_pos_img">
            <span class="zoom-in" (click)="zoomin()"
              ><i class="fa fa-plus" aria-hidden="true"></i></span
            ><br />
            <span class="zoom-out" (click)="zoomout()"
              ><i class="fa fa-minus" aria-hidden="true"></i
            ></span>
          </span>
        </div>
        <div *ngIf="!isImgBoolean" style="position: relative">
          <div *ngIf="isLoaded">
            <div class="btnspdf">
              <div>
                <button
                  (click)="prevPage()"
                  [disabled]="page === 1"
                  class="preBtn"
                >
                  Prev
                </button>
                <span class="totalPages">{{ page }} / {{ totalPages }}</span>
                <button
                  (click)="nextPage()"
                  [disabled]="page === totalPages"
                  class="preBtn"
                >
                  Next
                </button>
              </div>

              <div class="d-flex">
                <button class="preBtn" (click)="rotate(-90)">
                  <i class="fa fa-undo" aria-hidden="true"></i>
                </button>
                <div class="f-12 ml-1 p-r-t">Rotate</div>
                <button class="preBtn mr-1" (click)="rotate(90)">
                  <i class="fa fa-repeat" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <div>
              <span class="zoom zoom_pos_pdf">
                <span class="zoom-in" (click)="zoomIn()"
                  ><i class="fa fa-plus" aria-hidden="true"></i></span
                ><br />
                <span class="zoom-out" (click)="zoomOut()"
                  ><i class="fa fa-minus" aria-hidden="true"></i
                ></span>
              </span>
            </div>
          </div>

          <pdf-viewer
            #pdfviewer
            [src]="showInvoice"
            style="width: 100%; overflow: auto"
            [show-all]="true"
            [zoom]="zoomdata"
            [original-size]="false"
            [fit-to-page]="true"
            [page]="page"
            [rotation]="rotation"
            [render-text]="true"
            [render-text-mode]="2"
            (after-load-complete)="afterLoadComplete($event)"
            (text-layer-rendered)="textLayerRendered($event)"
            (mouseup)="selectedText()"
          >
          </pdf-viewer>
        </div>
      </section>
      <div *ngIf="!isDesktop" class="doc_cls_btn" (click)="doc_view()">
        <i class="fa fa-close"></i>
      </div>
      <div *ngIf="!isDesktop">
        <app-pdfview
          [vendorName]="vendorName"
          [invoiceNumber]="invoiceNumber"
          [btnText]="btnText"
          (boolEmit)="getPdfBool($event)"
          (PdfBtnClick)="viewPdf()"
        ></app-pdfview>
      </div>
      <!-- <img [src]="imageUrl" style="width: 100%;height: 80vh;"><br/> -->
    </div>
  </div>
</div>

<!-- Rule selection modal-->
<p-dialog
  header="Change Rule For Batch "
  [(visible)]="displayRuleDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '31vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <mat-radio-group
      class="example-radio-group"
      [(ngModel)]="SelectRuleOption.value"
    >
      <mat-radio-button
        class="example-radio-button f-13"
        *ngFor="let rule of givenRules"
        [value]="rule"
        [disabled]="rule.IsActive == 0"
      >
        {{ rule.Name }}
        <span *ngIf="rule.IsActive == 0" class="f-11 ml-1 glow"
          >Coming soon</span
        >
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="displayRuleDialog = false"
      >
        Cancel
      </button>
      <button
        *ngIf="!save_rule_boolean"
        type="button"
        class="btn btnVender bg-btn-success"
        (click)="sendReview()"
      >
        Send for Review Batch
      </button>
      <button
        *ngIf="save_rule_boolean"
        type="button"
        class="btn btnVender bg-btn-success"
        (click)="save_rule()"
      >
        Save
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- Add label-->
<p-dialog
  [header]="headerpop"
  [(visible)]="progressDailogBool"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: p_width }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [closeIcon]="false"
>
  <div *ngIf="headerpop == 'Batch Progress'">
    <div class="f-12" *ngIf="!(batchData?.length > 0)">
      Hey, Please hold on the batch is running...
    </div>
    <ul class="f-12" *ngIf="batchData?.length > 0">
      <li *ngFor="let st of batchData">
        <span [ngStyle]="{ color: st.status == 1 ? 'green' : 'red' }">{{
          st.msg
        }}</span>
      </li>
    </ul>

    <div *ngIf="batch_count > 2" class="f-12 mt-2" style="color:red;">
      Oops, looks like something is wrong with the invoice, Please check with the Serina support team.
    </div>
  </div>
  <!-- <div
    *ngIf="headerpop == 'Select GRN'"
    class="text-center"
    style="min-height: 150px"
  >
    <p-multiSelect
      [options]="grnList"
      placeholder="GRN Number"
      filter="false"
      inputStyleClass="form-control mr-2"
      optionLabel="docheaderID"
      [maxSelectedLabels]="3"
      [selectedItemsLabel]="'{0} items selected'"
      name="PO_GRN_Number"
      [(ngModel)]="selectedGRNList"
      required
    >
    </p-multiSelect>
  </div> -->

  <div
    *ngIf="headerpop == 'GRN Creation Status' || headerpop == 'GRN Approval Status' "
    class="f-13 text-center"
    style="color: #f38a6b"
  >
    {{ APIResponse }}
  </div>
  <div class="row" *ngIf="headerpop == 'Confirm PO number' || headerpop == 'Select GRN'" style="min-height: 40vh;">
    <div class="col-md-4">
      <input type="text" class="form-control f-12" (keyup)="POsearch($event.target.value)" placeholder="Search..">
      <ul class="ulPOlist f-12 mt-1" *ngIf="headerpop == 'Confirm PO number'">
        <li *ngFor="let poitem of poNumbersList;let i = index;" [id]="i" (click)="selectedPO(i,$event.target.value)"
         [ngClass]="activePOId == poitem.Document.PODocumentID ? 'activePO':''">{{poitem.Document.PODocumentID}}</li>
      </ul>
      <div class="f-12" *ngIf="!(poNumbersList?.length>0) && headerpop == 'Confirm PO number'">
        Sorry! NO records found.
      </div>

      <ul class="ulPOlist f-12 mt-1" *ngIf="headerpop == 'Select GRN'">
        <li *ngFor="let grn_item of grnList;let i = index;" [id]="i" >
          <input type="checkbox" [id]="grn_item.GRNNumber" (change)="selectedGRN($event,grn_item.GRNNumber)" class="mr-2"> 
          <label [for]="grn_item.GRNNumber">{{grn_item.GRNNumber}} <span style="color: #358cd0;" *ngIf="grn_item?.InvoiceNumber">| Inv - </span> {{grn_item?.InvoiceNumber}}</label>
        </li>
      </ul>
      <div class="f-12" *ngIf="!(grnList?.length>0) && headerpop == 'Select GRN'">
        Sorry! NO records found.
      </div>
    </div>
    <div class="col-md-8">
      <div class="d-flex justify-content-end" *ngIf="headerpop == 'Confirm PO number'">
        <p-calendar [(ngModel)]="selectDate" view="month" dateFormat="mm/yy" [showIcon]="true" [yearNavigator]="true" [minDate]="minDate" 
        [maxDate]="maxDate" [yearRange]='displayYear' placeholder="Select dates to filter.." [readonlyInput]="false"
        inputId="monthpicker"></p-calendar>
        <button class="btnUpload filter_btn_m " (click)="applyDatefilter(selectDate)"> Filter</button>
      </div>
      <h6 class="f-14 mt-1 d-flex justify-content-between" *ngIf="PO_GRN_Number_line.length>0">
        <div>Line details</div>
        <div class="f-13 po_date" *ngIf="headerpop == 'Confirm PO number' && poDate">PO Date: {{ poDate[0]?.DocumentData?.Value | date:'medium'}}</div>
      </h6>
      <table class="table f-12 " *ngIf="PO_GRN_Number_line.length>0 && headerpop == 'Confirm PO number'">
        <tr>
          <th style="width: 60px;">S.No</th> <th>Description</th> <th>Quantity</th> <th>UnitPrice</th>
        </tr>
        <tr *ngFor="let item of PO_GRN_Number_line; let i = index;">
          <td>{{ i+1 }}</td>
          <td>{{ item.Name }}</td>
          <td> {{ item.PurchQty }} </td>
          <td>{{ item.UnitPrice }}</td>
        </tr>
      </table>
      <ng-container  *ngIf="headerpop == 'Select GRN'">
        <div *ngFor="let grnLine of selectedGRNLines">
          <div class="f-13">GRN : {{grnLine?.grnNumber}}</div>
          <table class="table f-12 " *ngIf="grnLine?.linesData?.length>0" >
            <tr>
              <th style="width: 60px;">S.No</th> <th>Description</th> <th>Quantity</th> <th>UnitPrice</th><th>AmountExcTax</th>
            </tr>
            <tr *ngFor="let item of grnLine?.linesData; let i = index;">
              <td>{{ i+1 }}</td>
              <td>{{ item.Description }}</td>
              <td> {{ item.Quantity }} </td>
              <td>{{ item.UnitPrice }}</td>
              <td>{{ item.AmountExcTax }}</td>
            </tr>
          </table>
        </div>
        <div *ngIf="selected_GRN_total" class="d-flex justify-content-between total_grn f-12">
          <div>Total</div>
          <div>{{ selected_GRN_total }}</div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button
        *ngIf="headerpop == 'Batch Progress'"
        type="button"
        class="btn btnVender bg-btn-success mt-2"
        (click)="isBatchFailed && batch_count <= 2 ? syncBatch() :routeToMapping()"
      >
        <span>{{ isBatchFailed && batch_count <= 2 ? "Re-try":"Next" }}</span>
      </button>
      <button
        *ngIf="headerpop != 'Batch Progress'"
        type="button"
        class="btn btnVender bg-btn-cancel mt-2 mr-2"
        (click)="closeDialog()"
      >
        Close
      </button>
      <button
        *ngIf="headerpop == 'Select GRN'"
        type="button"
        class="btn btnVender bg-btn-success mt-2"
        [disabled]="!(selectedGRNList?.length > 0)"
        (click)="ChangeGRNData()"
      >
        Change
      </button>
      <button
        *ngIf="headerpop == 'GRN Creation Status'"
        type="button"
        class="btn btnVender bg-btn-success mt-2 mr-2"
        (click)="backToInvoice()"
      >
        Go Back
      </button>
      <button *ngIf="headerpop == 'Confirm PO number'" type="button" class="btn btnVender bg-btn-success mt-2" [disabled]="!activePOId" (click)="confirmPO()">Confirm</button>
    </div>
  </ng-template>
</p-dialog>

<!-- edit type selection modal-->
<p-dialog
  header="Select Error type "
  [(visible)]="displayErrorDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '31vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <mat-radio-group
      class="example-radio-group"
      [(ngModel)]="SelectErrorOption"
    >
      <mat-radio-button
        class="example-radio-button f-13"
        *ngFor="let rule of givenErrors"
        [value]="rule.idbatcherrortypes"
      >
        {{ rule.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="cancelSelectErrorRule()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btnVender bg-btn-success"
        (click)="updateLine()"
      >
        Save Changes
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- Reject modal-->
<p-dialog
  header="ADD Rejection Comments "
  [(visible)]="displayrejectDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '31vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <div class="d-flex justify-content-between">
      <label class="f-12 mt-2 mb-0"
        >Please select the reason for rejection</label
      >
      <i
        *ngIf="this.rejectionComments != ''"
        class="fa fa-edit ml-1"
        style="cursor: pointer"
        (click)="open_dialog_comp('Amend')"
      ></i>
    </div>
    <select
      *ngIf="!addrejectcmtBool"
      name="reject"
      id="reason"
      class="form-control f-12"
      (change)="selectReason(rejectionComments)"
      [(ngModel)]="rejectionComments"
    >
      <option value="" disabled>Select reason</option>
      <option *ngFor="let x of rejectReason" [value]="x.reason">
        {{ x.reason }}
      </option>
    </select>
    <input
      *ngIf="addrejectcmtBool"
      type="text"
      class="form-control f-12"
      (keyup)="rejectKepup($event.target.value)"
      placeholder="Please enter the reason"
    />
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="displayrejectDialog = false"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btnVender bg-btn-success"
        [disabled]="rejectionComments == '' || rejectionComments == 'Others'"
        [ngStyle]="{
          cursor:
            rejectionComments == '' || rejectionComments == 'Others'
              ? 'not-allowed'
              : 'pointer'
        }"
        (click)="Reject()"
      >
        Send for Rejection
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
