import { SharedService } from 'src/app/services/shared.service';
import { PermissionService } from './../../../services/permission.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AlertService } from './../../../services/alert/alert.service';
import { ExceptionsService } from './../../../services/exceptions/exceptions.service';
import { ImportExcelService } from './../../../services/importExcel/import-excel.service';
import { TaggingService } from './../../../services/tagging.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe, Location } from '@angular/common';
import { DataService } from 'src/app/services/dataStore/data.service';
import { DateFilterService } from 'src/app/services/date/date-filter.service';
import { AuthenticationService } from 'src/app/services/auth/auth-service.service';

@Component({
  selector: 'app-batch-process',
  templateUrl: './batch-process.component.html',
  styleUrls: ['./batch-process.component.scss'],
})
export class BatchProcessComponent implements OnInit {
  ColumnsForBatch = [
    { field: 'docheaderID', header: 'Invoice Number' },
    { field: 'VendorName', header: 'Vendor Name' },
    { field: 'EntityName', header: 'Entity Name' },
    { field: 'CreatedOn', header: 'Uploaded Date' },
    { field: 'PODocumentID', header: 'PO number' },
    { field: 'sender', header: 'Sender' },
    { field: 'status', header: 'Status' },
    { field: 'totalAmount', header: 'Amount' },
  ];
  serviceColumns = [
    { field: 'docheaderID', header: 'Invoice Number' },
    { field: 'ServiceProviderName', header: 'Serviceprovider Name' },
    { field: 'Account', header: 'Serviceprovider A/C' },
    { field: 'EntityName', header: 'Entity Name' },
    { field: 'status', header: 'Status' },
    { field: 'sourcetype', header: 'Source' },
    { field: 'CreatedOn', header: 'Uploaded Date' },
    { field: 'totalAmount', header: 'Amount' },
  ];
  columnsData = [];
  showPaginatorAllInvoice: boolean;
  columnsToDisplay = [];

  ColumnsForBatchApproval = [
    { field: 'docheaderID', header: 'Invoice Number' },
    { field: 'VendorName', header: 'Vendor Name' },
    { field: 'Name', header: 'Rule' },
    // { field: 'documentdescription', header: 'Description' },
    // { field: 'All_Status', header: 'Status' },
    { field: 'Approvaltype', header: 'Approval Type' },
    { field: 'totalAmount', header: 'Amount' },
  ];
  columnsToDisplayBatchApproval = [];
  viewType: any;
  allSearchInvoiceString: any[];
  rangeDates: Date[];
  dataLength: number;
  columnsDataAdmin: any[];
  showPaginatorApproval: boolean;
  dataLengthAdmin: number;
  batchProcessColumnLength: number;
  approvalPageColumnLength: number;
  dashboardViewBoolean: boolean;
  heading: string;
  isVendorBoolean: boolean;
  filterData: any[];
  minDate: Date;
  maxDate: Date;
  isCustomerPortal:boolean;
  requestorList = ['All'];
  filteredRequestorList: any[];

  isDesktop: boolean;
  refreshBool: boolean;
  user_access: any;

  constructor(
    private tagService: TaggingService,
    private ImportExcelService: ImportExcelService,
    private ngxSpinner: NgxSpinnerService,
    private MessageService: MessageService,
    private alertService: AlertService,
    private router: Router,
    private exceptionService: ExceptionsService,
    private permissionService: PermissionService,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    public ds: DataService,
    private datePipe: DatePipe,
    private dateFilterService: DateFilterService
  ) { }

  ngOnInit(): void {
    this.isCustomerPortal = this.sharedService.isCustomerPortal;
    this.isDesktop = this.ds.isDesktop;
    this.user_access = this.authService.currentUserValue['permissioninfo'].NameOfRole.toLowerCase();
    if (this.permissionService.excpetionPageAccess == true) {
      // if(!this.dataS.ipAddress){
      //   this.getIPAddress()
      // }
      this.viewType = this.tagService.batchProcessTab;

      if (!this.isDesktop) {
        this.mob_columns();
      }
      this.findRoute();

      this.dateRange();
      // this.getApprovalBatchData();
    } else {
      alert("Sorry!, you do not have access");
      this.router.navigate(['customer/invoice/allInvoices'])
    }

  }
  dateRange() {
    this.dateFilterService.dateRange();
    this.minDate = this.dateFilterService.minDate;
    this.maxDate = this.dateFilterService.maxDate;
  }

  mob_columns() {
    this.ColumnsForBatch = [
      { field: 'docheaderID', header: 'Invoice Number' },
      { field: 'VendorName', header: 'Vendor Name' },
      { field: 'EntityName', header: 'Entity Name' },
      { field: 'status', header: 'Status' },
    ];
    this.serviceColumns = [
      { field: 'docheaderID', header: 'Invoice Number' },
      { field: 'ServiceProviderName', header: 'Serviceprovider Name' },
      { field: 'Account', header: 'Serviceprovider A/C' },
      { field: 'status', header: 'Status' },
    ];
  }

  findRoute() {
    if (
      this.router.url.includes(
        'ExceptionManagement/Service_ExceptionManagement'
      )
    ) {
      this.heading = 'Service based OCR Exceptions';
      this.isVendorBoolean = false;
      this.ColumnsForBatch = this.serviceColumns;
      this.getServiceInvoiceData();
    } else {
      this.heading = 'Vendor based Exception';
      this.isVendorBoolean = true;
      this.getBatchInvoiceData();
    }
    if (this.router.url.includes('home')) {
      this.dashboardViewBoolean = true;
    } else {
      this.dashboardViewBoolean = false;
    }
    this.prepareColumnsArray();
  }
  // getIPAddress() {
  //   window.addEventListener('load', async () => {
  //     const response = await fetch('https://ip.seeip.org/jsonip?');
  //     const data = await response.json();
  //     console.log(data.ip);
  //     if(data.ip)
  //     this.dataS.ipAddress = data.ip;
  //   });   
  // }

  // to prepare display columns array
  prepareColumnsArray() {
    if (this.dashboardViewBoolean == true) {
      this.ColumnsForBatch = this.ColumnsForBatch.filter((ele) => {
        return ele.header != 'Status';
      });
    }
    this.ColumnsForBatch.filter((element) => {
      this.columnsToDisplay.push(element.field);
      // this.invoiceColumnField.push(element.field)
    });
    this.ColumnsForBatchApproval.filter((ele) => {
      this.columnsToDisplayBatchApproval.push(ele.field);
    });

    this.batchProcessColumnLength = this.ColumnsForBatch.length + 1;
    this.approvalPageColumnLength = this.ColumnsForBatchApproval.length + 1;
  }

  chooseEditedpageTab(value) {
    this.viewType = value;
    this.tagService.batchProcessTab = value;
    this.allSearchInvoiceString = [];
  }

  searchInvoiceDataV(value) {
    // this.allSearchInvoiceString = []
    this.allSearchInvoiceString = value.filteredValue;
  }

  getBatchInvoiceData() {
    this.ngxSpinner.show();
    this.refreshBool = true;

    this.exceptionService.readBatchInvoicesData().subscribe(
      (data: any) => {
        const batchData = [];
        data.forEach((element) => {
          let mergeData = {
            ...element.Document,
            ...element.DocumentSubStatus,
            ...element.Entity,
            ...element.Vendor,
          };
          mergeData['substatus'] = element.substatus;
          mergeData['documentdescription'] = element?.documentDescription;
          batchData.push(mergeData);
          this.requestorList.push(element?.Document?.requestorName)
        });
        this.ngxSpinner.hide();

        this.requestorList = [...new Set(this.requestorList)]
        this.columnsData = batchData.sort((a, b) => {
          let c = new Date(a.CreatedOn).getTime();
          let d = new Date(b.CreatedOn).getTime();
          return d - c
        });
        this.filterData = this.columnsData;
        if(this.ds.exceptionByUserBool){
          this.filterByUser();
        }
        this.dataLength = this.columnsData.length;
        if (this.dataLength > 10) {
          this.showPaginatorAllInvoice = true;
        }
        this.refreshBool = false;
      },
      (error) => {
        this.ngxSpinner.hide();
        this.alertService.errorObject.detail = error.statusText;
        this.MessageService.add(this.alertService.errorObject);
      }
    );
  }
  onSelectAll(bool) {
    this.ds.exceptionByUserBool = bool;
    this.columnsData = this.filterData;
    if(bool){
      this.filterByUser();
    } 
    this.dataLength = this.columnsData.length;
      if (this.dataLength > 10) {
        this.showPaginatorAllInvoice = true;
      }
  }

  filterByUser(){
    const vendor_queue = [29, 7, 16, 21]
    const receiver_queue = [8, 34, 22, 17, 75, 21]
    if (this.user_access == 'receiver') {
      this.columnsData = this.columnsData.filter(id => {
        return receiver_queue.includes(id.documentsubstatusID);
      })
    } else if (this.user_access == 'vendor super user') {
      this.columnsData = this.columnsData.filter(id => {
        return vendor_queue.includes(id.documentsubstatusID);
      })
    }
    this.dataLength = this.columnsData.length;
    if (this.dataLength > 10) {
      this.showPaginatorAllInvoice = true;
    }
  }
  getServiceInvoiceData() {
    this.ngxSpinner.show();
    this.sharedService.readEditedServiceInvoiceData().subscribe(
      (data: any) => {
        let invoiceArray = [];
        data.exception_service_invoices.forEach((element) => {
          let invoices = {
            ...element.Document,
            ...element.DocumentSubStatus,
            ...element.Entity,
            ...element.ServiceProvider,
            ...element.ServiceAccount,
          };
          invoiceArray.push(invoices);
        });
        this.columnsData = invoiceArray.sort((a, b) => {
          let c = new Date(a.CreatedOn).getTime();
          let d = new Date(b.CreatedOn).getTime();
          return d - c
        });
        this.filterData = this.columnsData;
        this.dataLength = this.columnsData.length;
        if (this.dataLength > 10) {
          this.showPaginatorAllInvoice = true;
        }
        this.ngxSpinner.hide();
      },
      (error) => {
        this.ngxSpinner.hide();
        this.alertService.errorObject.detail = error.statusText;
        this.MessageService.add(this.alertService.errorObject);
      }
    );
  }

  // getApprovalBatchData() {
  //   this.ngxSpinner.show();
  //   this.exceptionService.readApprovalBatchInvoicesData().subscribe(
  //     (data: any) => {
  //       const batchData = [];
  //       data.forEach((element) => {
  //         let mergeData = {
  //           ...element.Document,
  //           ...element.DocumentSubStatus,
  //           ...element.Rule,
  //           ...element.Vendor,
  //           ...element.DocumentRuleupdates,
  //         };
  //         mergeData.Approvaltype = element.Approvaltype;
  //         batchData.push(mergeData);
  //       });
  //       this.columnsDataAdmin = batchData;
  //       this.dataLengthAdmin = this.columnsDataAdmin.length;
  //       if (this.dataLengthAdmin > 10) {
  //         this.showPaginatorApproval = true;
  //       }
  //       this.ngxSpinner.hide();
  //     },
  //     (error) => {
  //       this.ngxSpinner.hide();
  //       this.alertService.errorObject.detail = error.statusText;
  //       this.MessageService.add(this.alertService.errorObject);
  //     }
  //   );
  // }
  exportExcel() {
    let exportData = [];
    if (this.tagService.batchProcessTab == 'normal') {
      exportData = this.columnsData;
    } else if (this.tagService.batchProcessTab == 'editApproveBatch') {
      exportData = this.columnsDataAdmin;
    }
    if (this.allSearchInvoiceString && this.allSearchInvoiceString.length > 0) {
      this.ImportExcelService.exportExcel(this.allSearchInvoiceString);
    } else if (exportData && exportData.length > 0) {
      this.ImportExcelService.exportExcel(exportData);
    } else {
      alert('No Data to import');
    }
  }
  filterByDate(date) {
    if (date != '') {
      const frmDate = this.datePipe.transform(date[0], 'yyyy-MM-dd');
      const toDate = this.datePipe.transform(date[1], 'yyyy-MM-dd');
      this.columnsData = this.filterData;
      this.columnsData = this.columnsData.filter((element) => {
        const dateF = this.datePipe.transform(element.CreatedOn, 'yyyy-MM-dd')
        return dateF >= frmDate && dateF <= toDate;
      });
      this.dataLength = this.columnsData.length;
    } else {
      this.columnsData = this.filterData;
      this.dataLength = this.columnsData.length;
    }
  }
  clearDates() {
    this.filterByDate('');
  }
  onSelectRequestor(event){
    this.ngxSpinner.show();
    if(event != 'All') {
      this.columnsData = this.filterData;
      this.columnsData = this.columnsData.filter(ele=>{
        return event.toLowerCase() == ele?.requestorName?.toLowerCase();
      })
    } else {
      this.columnsData = this.filterData;
      this.dataLength = this.columnsData?.length;
    }
    this.ngxSpinner.hide();
  }
  filterByRequestor(event) {
    let filtered: any[] = [];
    let query = event.query;
    console.log(this.requestorList)
    for (let i = 0; i < this.requestorList.length; i++) {
      let name = this.requestorList[i];
      if (
        name?.toLowerCase().includes(query.toLowerCase())
      ) {
        filtered.push(name);
      }
    }
    this.filteredRequestorList = filtered;
  }
}
